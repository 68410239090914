import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useStores } from "../../../stores/setup/use-store";
import { Box, Progress } from "@chakra-ui/react";
import {
  ImageOverlay,
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import "./Styles.css";
import { divIcon, LatLngBounds, LatLngExpression } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { IStation } from "../../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import {
  extractCoordinates,
  getCoordinatesWithinRadius,
} from "../../../util/getCoordinatesWithinRadius/getCoordinatesWithinRadius";
import { DateTime } from "luxon";
import useFetchInsightMultiStation from "../../../hooks/useFetchInsightMultiStation";
import { Center, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { roundToNearestHour } from "../../../util/roundHours/roundHours";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";

import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { LAST_SELECTED_STATION_KEY } from "../../../constants";
import MarkerClusterGroup from "react-leaflet-cluster";
import CustomMarker from "./Components/CustomMarker";

export interface MapMarkerChildProps {
  station: IStation;
}

export interface Radar {
  id: number;
  nome: string;
  localidade: string;
  raio: number;
  lat_center: string;
  lon_center: string;
  lon_min: string;
  lon_max: string;
  lat_min: string;
  lat_max: string;
  path: string;
  tamanho: number;
  data: string;
}

export interface RadarResponse {
  status: boolean;
  message: number;
  data: {
    tipo: string;
    anima: string[];
    radar: Radar[][];
  };
}

interface Station {
  station_id: number;
  region_id: number;
}

interface MarkerOptions {
  station?: Station;
}

interface MarkerData {
  options: MarkerOptions;
}

interface Cluster {
  getAllChildMarkers(): MarkerData[];
  getChildCount(): number;
}

const ForecastChartPanelMap: React.FC = () => {
  const {
    dataStores: {
      forecastChartPageStore,
      regionStore,
      forecastChartInsightStore,
      forecastChartMeteogramStore,
      authStore,
    },
  } = useStores();

  const [renderClusters, setRenderClusters] = useState(true);

  useFetchInsightMultiStation(
    forecastChartInsightStore,
    forecastChartPageStore,
    forecastChartMeteogramStore.selectedInsight?.id
  );

  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMap",
  });

  const toast = useToast();
  const toastId = "toastId";
  const toastRadarId = "toastRadarId";

  const customMarker = useMemo(() => {
    return forecastChartPageStore.stations.map((station) => (
      <CustomMarker
        key={`${station.station_id}-${station.region_id}`}
        station={station}
      />
    ));
  }, [forecastChartPageStore.stations]);

  const createClusterCustomIcon = (cluster: Cluster) => {
    const markers = cluster.getAllChildMarkers();

    const isSelected = markers.some((marker) => {
      const station = marker.options.station;
      return (
        station?.station_id === forecastChartPageStore.selectedStation &&
        station?.region_id === forecastChartPageStore.regionId
      );
    });

    return divIcon({
      html: renderToStaticMarkup(
        <div
          style={{
            position: "relative",
            width: "50px",
            backgroundColor: isSelected ? "#555c5d" : "#454b4c",
            border: isSelected ? "1px solid" : "none",
            borderColor: isSelected ? "#FFF" : "none",
            color: "#FFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
            fontWeight: "bold",
            fontSize: "11px",
            cursor: "pointer",
            padding: "2px",
          }}
        >
          <p style={{ margin: 0, textAlign: "center", lineHeight: "1.2" }}>
            {cluster.getChildCount()} <br /> stations
          </p>
        </div>
      ),
    });
  };

  const reloadMultiStationInsight = () => {
    if (
      !forecastChartPageStore.insightMode ||
      !forecastChartMeteogramStore.selectedInsight
    ) {
      return;
    }

    if (!forecastChartInsightStore.insightMultiStation) {
      return;
    }

    const now = DateTime.utc().toMillis();
    const hasOutDatedData =
      forecastChartInsightStore.insightMultiStation.stationsData.find(
        (s) => now - s.calculatedAt > 15 * 60 * 1000
      );

    if (!hasOutDatedData) {
      return;
    }

    if (!toast.isActive(toastId)) {
      toast({
        id: "toastId",
        title: t("insightDataUpdate"),
        description: t("insightDataUpdateDescription"),
        status: "info",
        position: "top",
        isClosable: true,
        duration: null,
      });
    }

    forecastChartInsightStore.setInsightMultiStation(null);
    forecastChartInsightStore.setSelectedCoordinatesWithinRadius([]);
    forecastChartInsightStore.setTimelineDate(null);

    const { station_lat, station_lon } =
      forecastChartPageStore.getStationByIdAndRegionId(
        forecastChartPageStore.selectedStation,
        forecastChartPageStore.regionId
      ) || {};

    if (!station_lat || !station_lon) {
      return;
    }

    const stationCoordinates = {
      latitude: station_lat,
      longitude: station_lon,
    };

    const coordinatesList = getCoordinatesWithinRadius(
      stationCoordinates,
      extractCoordinates(forecastChartPageStore.stations),
      forecastChartInsightStore.radius
    );

    forecastChartInsightStore.setSelectedCoordinatesWithinRadius(
      coordinatesList
    );
    forecastChartMeteogramStore.resetInsightMeteogram();

    const roundedNow = roundToNearestHour(now);
    forecastChartInsightStore.setTimelineDate({
      start: roundedNow.toMillis(),
      end: roundedNow.plus({ hours: 24 }).toMillis(),
      timezoneOffset: regionStore.mainRegionTimezoneOffset!,
    });
    forecastChartInsightStore.setSelectedTimelineDate({
      start: roundedNow.toMillis(),
      end: roundedNow.plus({ hours: 3 }).toMillis(),
      timezoneOffset: regionStore.mainRegionTimezoneOffset!,
    });
  };

  useEffect(() => {
    forecastChartPageStore.setEmptyRadarData(false);
    const companyId = localStorage.getItem("companyId") || "0";

    const showRadar = [81, 59, 79, 42, 29, 10, 46, 19, 66, 80].some(
      (x) => x === parseInt(companyId)
    );

    if (showRadar) {
      const fetchRadarData = () => {
        if (
          forecastChartPageStore.tabStateActivty ===
          EnvironmentalType.PRECIPITATION
        ) {
          forecastChartPageStore.getRadarData();
        }
      };

      fetchRadarData();

      const intervalId = setInterval(fetchRadarData, 3 * 60 * 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartPageStore.tabStateActivty, authStore.companyId]);

  useEffect(() => {
    const intervalId = setInterval(reloadMultiStationInsight, 10 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!forecastChartPageStore.emptyRadarData) return;

    if (!toast.isActive(toastRadarId)) {
      toast({
        id: toastRadarId,
        title: t("noRadarDataAvailable"),
        status: "info",
        position: "top",
        isClosable: true,
        duration: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartPageStore.emptyRadarData]);

  const MapChild = () => {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        map.locate();
        forecastChartMeteogramStore.setOpenMeteogram(true);
        forecastChartMeteogramStore.resetInsightMeteogram();

        forecastChartPageStore.setSelectedStation(0);
        forecastChartPageStore.setClickMapLatLng(e.latlng);
        forecastChartPageStore.setStationInfo();
        forecastChartPageStore.updateMacroRegionOrigin(
          { lat, lng },
          regionStore.macroRegions
        );
        forecastChartPageStore.setActionCallMapClick();
      },
    });

    return <></>;
  };

  const customMarkerUserIconSelected = divIcon({
    html: renderToStaticMarkup(
      <i style={{ color: "#2E3233" }} className="far fa-star fa-1x" />
    ),
  });

  const getCenterMap = useCallback((): LatLngExpression => {
    const lastStation = localStorage.getItem(LAST_SELECTED_STATION_KEY);

    if (
      forecastChartPageStore.mapCenterLatitude &&
      forecastChartPageStore.mapCenterLongitude
    ) {
      const parsedStation =
        forecastChartPageStore.parseStationFromStorage(lastStation);

      const lastSelectedStation =
        forecastChartPageStore.stations.find(
          (station) =>
            station.station_id === parsedStation?.station_id &&
            station.region_id === parsedStation.region_id
        ) ?? forecastChartPageStore.stations[0];

      const mapCenterLatitude =
        lastSelectedStation?.station_lat ??
        forecastChartPageStore.mapCenterLatitude;
      const mapCenterLongitude =
        lastSelectedStation?.station_lon ??
        forecastChartPageStore.mapCenterLongitude;

      return [mapCenterLatitude, mapCenterLongitude];
    }
    return [0, 0];
  }, [forecastChartPageStore]);

  const FlyMapTo = () => {
    const map = useMap();

    useEffect(() => {
      if (forecastChartPageStore.searchedLatLng) {
        map.flyTo(forecastChartPageStore.searchedLatLng, 10);
        forecastChartPageStore.setSearchedLatLng(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forecastChartPageStore.searchedLatLng]);

    return null;
  };

  const SearchControl = () => {
    const map = useMap();

    const handleSearchLocation = useCallback((e: any) => {
      const { x, y } = e.location;

      forecastChartPageStore.setSelectedStation(0);
      forecastChartPageStore.setClickMapLatLng({
        lat: y,
        lng: x,
      });
      forecastChartPageStore.setStationInfo();
      forecastChartPageStore.updateMacroRegionOrigin(
        { lat: y, lng: x },
        regionStore.macroRegions
      );
    }, []);

    useEffect(() => {
      const provider = new OpenStreetMapProvider();
      //@ts-ignore
      const searchControl = new GeoSearchControl({
        provider,
        marker: {
          icon: customMarkerUserIconSelected,
        },
        searchLabel: t("searchAddress"),
        autoClose: true,
        retainZoomLevel: true,
      });

      searchControl.__proto__.onSubmit = function ({ query, data }: any) {
        const latLongRegex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
        const match = query.match(latLongRegex);

        if (match) {
          const [lat, lng] = query.split(",");
          map.flyTo(
            {
              lat,
              lng,
            },
            15
          );
          forecastChartPageStore.setSelectedStation(0);
          forecastChartPageStore.setClickMapLatLng({
            lat,
            lng,
          });
          forecastChartPageStore.setStationInfo();
          forecastChartPageStore.updateMacroRegionOrigin(
            { lat, lng },
            regionStore.macroRegions
          );
        } else if (data) {
          this.showResult(data);
        }
      };

      map.on("geosearch/showlocation", handleSearchLocation);

      map.addControl(searchControl);

      return () => {
        map.removeControl(searchControl);
        map.off("geosearch/showlocation", handleSearchLocation);
      };
    }, [map, handleSearchLocation]);

    useEffect(() => {
      const handleResizeTooltip = () => {
        const zoom = map.getZoom();
        if (zoom >= 10 && renderClusters) {
          setRenderClusters(false);
        } else if (zoom < 10 && !renderClusters) {
          setRenderClusters(true);
        }

        forecastChartPageStore.setMapZoom(zoom);
      };

      map.on("zoom", handleResizeTooltip);
    }, [map]);

    return null;
  };

  return (
    <MapContainer
      key={`mapStation-${forecastChartPageStore.mapCenterLatitude}`}
      center={getCenterMap()}
      zoom={forecastChartPageStore.mapZoom}
      scrollWheelZoom={true}
      className="map"
      attributionControl={false}
      zoomControl={true}
    >
      <FlyMapTo key={`id-${forecastChartPageStore.searchedLatLng}`} />

      <SearchControl />

      <MapChild />

      {forecastChartPageStore.tabStateActivty ===
        EnvironmentalType.PRECIPITATION &&
        forecastChartPageStore.radarData[0]?.map((radar) => {
          const { lat_min, lat_max, lon_min, lon_max, path, id } = radar;

          if (!path) {
            return null;
          }

          if (!lat_min || !lat_max || !lon_min || !lon_max) {
            console.error("Coordenadas inválidas para o radar: ", radar);
            return null;
          }

          const bounds = new LatLngBounds(
            [parseFloat(lat_min), parseFloat(lon_min)],
            [parseFloat(lat_max), parseFloat(lon_max)]
          );

          return (
            <ImageOverlay key={id} url={path} bounds={bounds} zIndex={10} />
          );
        })}

      <TileLayer
        attribution='&copy; <a href="https://www.i4sea.com">i4sea</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <Center
        hidden={!forecastChartInsightStore.loadingInsightMultiStation}
        bg="#0000005e"
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        top="30%"
        borderRadius="base"
        zIndex={99999}
        py="12px"
        px="16px"
      >
        <Box display="flex" flexDir="column" alignItems="center">
          <Progress
            colorScheme="blue"
            hasStripe={false}
            width="200px"
            size="xs"
            isIndeterminate
          />
          <Text color="white" fontWeight="medium">
            {t("loading")}...
          </Text>
        </Box>
      </Center>

      {forecastChartPageStore.clickMapLatLng &&
        forecastChartPageStore.selectedStation === 0 && (
          <Marker
            position={forecastChartPageStore.clickMapLatLng}
            icon={customMarkerUserIconSelected}
          ></Marker>
        )}

      {renderClusters ? (
        <MarkerClusterGroup
          chunkedLoading
          maxClusterRadius={45}
          iconCreateFunction={createClusterCustomIcon as any}
          showCoverageOnHover={false}
        >
          <div
            key={`${forecastChartPageStore.insightMode} - ${forecastChartInsightStore.insightMultiStation?.id} - ${forecastChartPageStore.tabStateActivty} - ${forecastChartMeteogramStore.visualizationType} - ${forecastChartInsightStore.loadingInsightMultiStation} - ${forecastChartPageStore.selectedStation}- ${forecastChartPageStore.regionId}`}
          >
            {customMarker}
          </div>
        </MarkerClusterGroup>
      ) : (
        <div
          key={`${forecastChartPageStore.sensorMarkerData}-${forecastChartPageStore.insightMode} - ${forecastChartInsightStore.insightMultiStation?.id} - ${forecastChartPageStore.tabStateActivty} - ${forecastChartMeteogramStore.visualizationType} - ${forecastChartInsightStore.loadingInsightMultiStation} - ${forecastChartPageStore.selectedStation}- ${forecastChartPageStore.regionId}`}
        >
          {customMarker}
        </div>
      )}
    </MapContainer>
  );
};

export default observer(ForecastChartPanelMap);
