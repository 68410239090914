import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Flex,
  useDisclosure,
  useToast,
  Center,
  Text,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import List from "../../components/List/List";
import { Input } from "../../components/Input/Input";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import { useStores } from "../../stores/setup/use-store";
import { IOccurrenceDescription } from "../../types/IOccurrence";
import { AlertDelete } from "../../components/AlertDelete/AlertDelete";
import Loading from "../../components/Loading/Loading";
import { StoreState } from "../../enum/StoreState";
import emptyOccurrences from "../../assets/empty_occurrences.svg";
import EmptyState from "../../components/EmptyState/EmptyState";
import OcurrenceImportModal from "./Components/OcurrenceImportModal";
import PublishIcon from "@material-ui/icons/Publish";
import { AlertSuccessImportModal } from "./Components/AlertSuccessImportModal";
import { AlertNewImportFunctionality } from "./Components/AlertNewImportFunctionality";
import { useLocation } from "react-router-dom";
import { SCREEN_HORIZONTAL_SPACING } from "../../constants";

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const Occurrences: React.FC = () => {
  const {
    dataStores: { occurrenceStore, authStore },
  } = useStores();
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "occurrence",
  });
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const location = useLocation();

  const { isOpen } = useDisclosure({
    isOpen: occurrenceStore.isImportOccurrenceModalOpen,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();

  const occurrencesList: IOccurrenceDescription[] =
    occurrenceStore.filteredOccurrences.map((occurrence) => {
      const { initialDate, id, description } = occurrence;
      const formattedDate = new Date(initialDate).toLocaleDateString(
        i18n.language,
        dateOptions
      );

      return {
        id,
        description: `${formattedDate} - ${description}`,
        initialDate: new Date(initialDate),
      };
    });

  const [occurrenceRemoveId, setOccurrenceDeleteId] = useState("");

  useEffect(() => {
    const isUserHasSeenImportModal =
      localStorage.getItem("isUserHasSeenImportModal") === "true";

    const params = new URLSearchParams(location.search);
    const open = params.get("open");

    if (!open) {
      setIsModalOpen(!isUserHasSeenImportModal);
    }
    occurrenceStore.loadStations();
    occurrenceStore.getOccurrences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchOccurrence = useCallback(
    (value: string) => {
      occurrenceStore.setSearchValue(value);

      occurrenceStore.setFilteredOccurrences(
        occurrenceStore.getFilteredOccurrences()
      );
    },
    [occurrenceStore]
  );

  const handleOnEdit = (occurrence: IOccurrenceDescription) => {
    const editOccurrence = occurrenceStore.occurrences.find(
      (item) => item.id === occurrence.id
    );
    if (editOccurrence) {
      occurrenceStore.loadStations();
      occurrenceStore.setSelectedOccurrence(editOccurrence);
      occurrenceStore.setIsOccurrenceModalOpen(true);
    } else {
      toast({
        title: t("errorYouNeedToSelectAnOccurrence"),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const handleOpenDeleteModal = (occurrence: IOccurrenceDescription) => {
    setOccurrenceDeleteId(occurrence.id);
    onDeleteOpen();
  };

  const handleOnConfirmDelete = () => {
    if (occurrenceRemoveId) {
      occurrenceStore.deleteOccurrence(occurrenceRemoveId);
      setOccurrenceDeleteId("");
      onDeleteClose();
    } else {
      toast({
        title: t("removalFailedContactUs"),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const handleOpenAddModal = () => {
    occurrenceStore.setIsOccurrenceModalOpen(true);
  };

  const handleOpenImportModal = () => {
    occurrenceStore.setIsImportOccurrenceModalOpen(true);
  };

  const handleCloseImportModal = () => {
    occurrenceStore.setIsImportOccurrenceModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    localStorage.setItem("isUserHasSeenImportModal", "true");
  };

  const handleConfirmModal = () => {
    handleCloseModal();
    handleOpenImportModal();
  };

  return (
    <Box mt={8} mx={SCREEN_HORIZONTAL_SPACING}>
      <PageTitle text={t("occurrences")} />
      <Text mb="1rem" color="gray.500" fontSize={"md"}>
        {t("occurrencesAreEventsThatImpactYourOperation")}
      </Text>

      <AlertNewImportFunctionality
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmModal}
        showButton={!authStore.readOnlyUser}
      />

      <AlertDelete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        titleLabel={t("deleteOccurrence")}
        bodyText={t("areYouSureWantDelete")}
        cancelButtonLabel={t("cancel")}
        deleteButtonLabel={t("delete")}
        handleDelete={handleOnConfirmDelete}
      />

      <AlertSuccessImportModal
        key={`id-${occurrenceStore.openSuccessImportModal}`}
        isOpen={occurrenceStore.openSuccessImportModal}
        onClose={() => occurrenceStore.setOpenSuccessImportModal(false)}
      />

      <OcurrenceImportModal isOpen={isOpen} onClose={handleCloseImportModal} />

      {occurrenceStore.state === StoreState.PEDDING && <Loading />}

      <Flex>
        <Input
          width="300px"
          placeholder={t("search") as string}
          icon={<SearchIcon />}
          value={occurrenceStore.searchValue}
          onChange={(e) => searchOccurrence(e.target.value)}
        />
        {!authStore.readOnlyUser && (
          <>
            <Button
              ml={4}
              leftIcon={<AddIcon />}
              layerStyle="ocean"
              _hover={{ layerStyle: "ocean" }}
              onClick={handleOpenAddModal}
            >
              {t("newOccurrence")}
            </Button>
            <Button
              ml={4}
              leftIcon={<PublishIcon />}
              layerStyle="lightOcean"
              _hover={{ layerStyle: "lightOcean" }}
              onClick={handleOpenImportModal}
            >
              {t("importOccurrence")}
            </Button>
          </>
        )}
      </Flex>

      <Center mt={12}>
        {!occurrenceStore.occurrences?.length &&
          occurrenceStore.state === StoreState.DONE &&
          !authStore.readOnlyUser && (
            <EmptyState
              description={t("youDonyHaveAOccurrenceYet")}
              buttonAction={handleOpenAddModal}
              buttonIcon={<AddIcon />}
              buttonText={
                !authStore.readOnlyUser
                  ? (t("addOccurrence") as string)
                  : undefined
              }
              image={emptyOccurrences}
              imageSize="20vw"
            />
          )}
      </Center>

      {authStore.readOnlyUser && (
        <Alert bg="#bee3f8" status="info" mb={4} color="gray.700">
          <AlertIcon />
          {i18n.t("readingModePleaseContact")}
        </Alert>
      )}
      <List
        list={occurrencesList}
        onEdit={handleOnEdit}
        onDelete={handleOpenDeleteModal}
        showDeleteButton={!authStore.readOnlyUser}
      />
    </Box>
  );
};
export default observer(Occurrences);
