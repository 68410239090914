import { CancelToken } from "axios";
import { IInsightMeteogram } from "../types/IInsight";
import api from "./api";

export interface IGetGanttChartDataParams {
  operationRuleId: number;
  create_at?: string;
  cancelToken?: CancelToken;
}

export interface IInsightMultiStation {
  id: number;
  name: string;
  timezoneOffset: number;
  stationsData: IInsightStation[];
}
export interface IInsightStation {
  regionId: number;
  stationId: number;
  innerCircle: string;
  outerCircle: string;
  calculatedAt: number;
}

export interface IInsightStationData {
  active: boolean;
  marginal: boolean;
  timestamp: number;
  color: string;
}

export default class OceanWeatherIntelligenceService {
  async getGanttChartData({
    operationRuleId,
    create_at,
    cancelToken,
  }: IGetGanttChartDataParams) {
    let result;

    result = await api.post(
      "/v1/ocean-weather-intelligence/getGanttChartData",
      {
        operationRuleId: operationRuleId,
        create_at: create_at,
      },
      { cancelToken }
    );
    return result.data;
  }

  async getStationForLocation(macroRegionId: number) {
    const result = await api.get(
      `/v1/ocean-weather-intelligence/getStationForLocation/${macroRegionId}`
    );
    return result.data;
  }

  async getEnvironmentalVariableForStation(
    stationId: number,
    macroRegionId: number
  ) {
    const result = await api.post(
      "/v1/ocean-weather-intelligence/getEnvironmentalVariableForStation",
      { stationId, macroRegionId }
    );
    return result.data;
  }

  async getInsightMeteogram(
    operationRuleLocationItemId: number,
    stationId: number,
    regionId: number
  ): Promise<IInsightMeteogram> {
    const result = await api.post(
      "v1/ocean-weather-intelligence/getInsightMeteogram",
      {
        operationRuleLocationItemId,
        stationId,
        regionId,
      }
    );

    return result.data;
  }

  async getInsightMultiStation(
    operationRuleLocationItemId: number,
    startTimestamp: number,
    endTimestamp: number,
    stations: { stationId: number; regionId: number }[],
    cancelToken?: CancelToken
  ): Promise<IInsightMultiStation> {
    const result = await api.post(
      "v1/ocean-weather-intelligence/getInsightMultiStation",
      {
        operationRuleLocationItemId,
        startTimestamp,
        endTimestamp,
        stations,
      },
      { cancelToken }
    );

    return result.data;
  }
}
