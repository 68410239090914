import { ConfigKey } from "../enum/ConfigKey";

const defaultConfig: Record<ConfigKey, any> = {
  // Debug mode
  DEBUG: false,

  VERSION: "2.0.41",

  // The node environment (development | test | production)
  NODE_ENV: "development",

  REACT_APP_BACKEND_API_URL: "https://i4cast-backend.demo.i4sea.com.br",

  REACT_APP_FIREBASE_CREDENTIAL:
    "ewogICAgICAgICJwcm9qZWN0SWQiOiAiaTRjYXN0LWVtdWxhdG9yLXByb2plY3QtaWQiCn0=", //emulator procject id

  FIREBASE_FIRESTORE_EMULATOR_HOST: "0.0.0.0:8200",

  REACT_APP_CONTACT_URL: "https://www.i4sea.com/",

  REACT_APP_SENTRY_DSN: "",
};

export const getConfig = (key: ConfigKey): string => {
  return process.env[key] || defaultConfig[key];
};
