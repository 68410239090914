import React, { useCallback, useState, useEffect } from "react";
import logo from "../../assets/logo.svg";
import pin from "../../assets/pin.svg";
import { IMenuItem } from "../../types/IMenuItem";
import { Link, useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Center,
  Box,
  Flex,
  Divider,
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Tooltip,
  useToast,
  keyframes,
} from "@chakra-ui/react";
import { IMacroRegion } from "../../types/IMacroRegion";
import { MacroRegion } from "../../enum/MacroRegion";
import { MenuItemProps, NavSize } from "../../stores/ui/menuView/menuViewStore";
import { useTranslation } from "react-i18next";
import ForecastChartMeteogramStore from "../../stores/data/forecastChart/forecastChartMeteogram/forecastChartMeteogramStore";
import { UserExperience } from "../../enum/UserExperience";
import { useStores } from "../../stores/setup/use-store";
import { EnvironmentalType } from "enum/EnvironmentalType";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import { Modal } from "antd";
import { Player } from "video-react";

export interface NavBarProps {
  menuList: MenuItemProps[];
  menuItemSelected: (
    menuList: IMenuItem[],
    pathname: string
  ) => number | undefined;
  handleNavBar: (value: NavSize) => void;
  pathname: string;
  mainRegion?: IMacroRegion;
  superUser: boolean;
  adminUser: boolean;
  forecastChartMeteogramStore: ForecastChartMeteogramStore;
  logUserExperience: (userExperience: UserExperience) => void;
}

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const NavBar: React.FC<NavBarProps> = ({
  menuList,
  menuItemSelected,
  handleNavBar,
  pathname,
  mainRegion,
  superUser,
  adminUser,
  forecastChartMeteogramStore,
  logUserExperience,
}) => {
  const {
    uiStores: { menuViewStore },
    dataStores: { shareStore },
  } = useStores();

  const navSize = menuViewStore.navSize;

  let history = useHistory();
  let player: any;
  const toast = useToast();
  const [menuListProcessed, setMenuListProcessed] = useState<MenuItemProps[]>(
    []
  );
  const { t } = useTranslation("translation", {
    keyPrefix: "navBar",
  });
  const [visibleModalvideo, setVisibleModalVideo] = useState(false);
  //TODO: Hard code for now, need to be changed for get backend
  useEffect(() => {
    const _menulist = menuList;
    _menulist.forEach((item) => {
      if (item.superUser && superUser) {
        item.hidden = false;
      }
      if (item.subMenuItem) {
        item.subMenuItem.forEach((subItem) => {
          if (subItem.superUser && superUser) {
            subItem.hidden = false;
          }
        });
      }
      if (item.admin && adminUser) {
        item.hidden = false;
      }
      if (item.subMenuItem) {
        item.subMenuItem.forEach((subItem) => {
          if (subItem.admin && adminUser) {
            subItem.hidden = false;
          }
        });
      }
    });

    const indexMooringLines = menuList.findIndex(
      (x) => x.title === t("mooringLines")
    );
    const indexSedimentationMap = menuList.findIndex(
      (x) => x.title === t("morphologyMap")
    );
    const indexDynamicUKC = menuList.findIndex(
      (x) => x.title === t("dynamicUkcCharts")
    );
    const indexRiverForecast = menuList.findIndex(
      (x) => x.title === t("riverForecast")
    );

    //For aliança Manaus
    if (companyId === "82" || companyId === "2") {
      if (menuList[indexRiverForecast]) {
        menuList[indexRiverForecast].hidden = false;
      }
    }

    if (MacroRegion.PORT_OF_PECEM === mainRegion?.origin) {
      if (menuList[indexMooringLines]) {
        menuList[indexMooringLines].hidden = false;
      }
      if (menuList[indexSedimentationMap]) {
        menuList[indexSedimentationMap].hidden = false;
      }
    } else {
      menuList[indexDynamicUKC].onClickMenu = undefined;
    }

    setMenuListProcessed(_menulist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superUser, adminUser, menuList, mainRegion]);

  const getBgColor = useCallback(
    (menuItemId: number | undefined) => {
      const selectedItem = menuItemSelected(menuListProcessed, pathname);
      return selectedItem === menuItemId ? "blue.400" : "";
    },
    [menuItemSelected, menuListProcessed, pathname]
  );

  const handleNavSize = () => {
    const navSizeValidation = navSize === "small" ? "large" : "small";

    handleNavBar(navSizeValidation);
    menuViewStore.setNavSize(navSizeValidation);
  };

  const handleVideoTutorial = () => {
    setVisibleModalVideo(!visibleModalvideo);
    localStorage.setItem("pulseVideoTutorialButton", "false");
  };

  const handleOpenNewsModal = () => {
    shareStore.getStationsAPI(EnvironmentalType.WEATHER); // EnvironmentalType ignored by Backend!
    logUserExperience(UserExperience.CLICK_NEWS_BUTTON);
    forecastChartMeteogramStore.setOpenShareModal(true);
    history.push("/forecast-charts?open=true");
  };

  const handleRedirectOnClick = (onClickMenu: () => void) => {
    toast({
      title: t("youAreGoingToBeRedirect"),
      status: "info",
      position: "top",
      isClosable: true,
    });
    onClickMenu();
  };

  const playerPause = () => {
    if (player) {
      player.pause();
    }
  };

  const companyId = localStorage.getItem("companyId") || "0";
  const isVattenfall = companyId === "48";

  const pulseVideoTutorial =
    localStorage.getItem("pulseVideoTutorialButton") === "true" ||
    localStorage.getItem("pulseVideoTutorialButton") === null;

  const pinSize = 8;

  return (
    <Box display={"flex"} p={1} zIndex={100}>
      <Modal
        title={t("learnHowToUse")}
        open={visibleModalvideo}
        width={"50%"}
        footer={null}
        onCancel={() => {
          player.pause();
          setVisibleModalVideo(!visibleModalvideo);
        }}
        afterClose={playerPause}
        closable={true}
      >
        <Player
          autoPlay
          ref={(ref: any) => {
            player = ref;
          }}
          src="https://i4sea.s3.us-west-2.amazonaws.com/public/Vattenfall+tutorial+video.mp4"
        />
      </Modal>
      <Center
        w={pinSize}
        h={pinSize}
        top="100px"
        right={-(pinSize / 2)}
        position="absolute"
        zIndex={100}
        transition="all 0.2s ease-out"
      >
        <img
          src={pin}
          alt="pin"
          style={{
            cursor: "pointer",
            marginRight: 6,
            transform: navSize === "small" ? "rotate(90deg)" : "rotate(0deg)",
          }}
          onClick={() => handleNavSize()}
        />
      </Center>
      <Flex
        display="flex"
        flexDirection="column"
        pt={8}
        height="full"
        fontSize="sm"
        w="100%"
        transition="all 0.2s ease-in-out"
      >
        <Box minH={102}>
          <Center>
            <img
              src={logo}
              alt="i4cast"
              style={{
                width: "60%",
                cursor: "pointer",
              }}
              onClick={() => {
                const urlHomeScreen = menuListProcessed.find(
                  (item) => item.homeScreen
                );
                history.push(urlHomeScreen?.url || "");
              }}
            />
          </Center>
        </Box>
        <Flex
          flexDirection="column"
          mt={"12px"}
          p={4}
          overflowY={"auto"}
          overflowX={"hidden"}
          height="70vh"
          sx={{
            "&::-webkit-scrollbar": {
              width: "12px",
              borderRadius: "8px",
              backgroundColor: `#267dbb`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.1)`,
              borderRadius: 8,
            },
          }}
        >
          {isVattenfall && (
            <Button
              size={"md"}
              color="white"
              bg="newBlue.500"
              border="1px solid #b0d2ec"
              _hover={{ backgroundColor: "rgba(49, 154, 221, 0.3)" }}
              onClick={handleVideoTutorial}
              display={navSize === "small" ? "none" : "flex"}
              px={2}
              minH={8}
              animation={pulseVideoTutorial ? `${pulse} 2s infinite` : ""}
              mb={2}
            >
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Text
                  isTruncated
                  fontWeight={"light"}
                  fontSize="13px"
                  mr={"8px"}
                >
                  {t("watchTutorial")}
                </Text>
                <VideoLibraryIcon style={{ fontSize: "18px" }} />
              </Flex>
            </Button>
          )}

          <Tooltip
            label={t("shareForecastWithOutLeaving")}
            fontSize="sm"
            placement="top-start"
            hasArrow
          >
            <Button
              size={"md"}
              color="white"
              bg="newBlue.500"
              border="1px solid #b0d2ec"
              _hover={{ backgroundColor: "rgba(49, 154, 221, 0.3)" }}
              onClick={handleOpenNewsModal}
              display={navSize === "small" ? "none" : "flex"}
              px={2}
              minH={8}
            >
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Text
                  isTruncated
                  fontWeight={"light"}
                  fontSize="13px"
                  mr={"8px"}
                >
                  {t("shareForecast")}
                </Text>
              </Flex>
            </Button>
          </Tooltip>
          {menuListProcessed &&
            menuListProcessed.map(
              ({
                id,
                icon,
                title,
                url,
                subMenuItem,
                hidden,
                oldIcon,
                onClickMenu,
                isNew,
                divider,
              }) => {
                if (subMenuItem && !hidden) {
                  return (
                    <>
                      {divider && (
                        <Box pt={3} pb={3}>
                          <Divider borderColor="gray.800" />
                        </Box>
                      )}

                      <Flex
                        mt={"8px"}
                        _hover={{
                          backgroundColor: "blue.400",
                          borderRadius: 4,
                        }}
                      >
                        <Box key={`item ${id}`} overflowX="hidden">
                          <Accordion ml={"24px"} allowToggle color="gray.100">
                            <AccordionItem border={0}>
                              <AccordionButton
                                _focus={{
                                  border: 0,
                                }}
                                _hover={{
                                  backgroundColor: "none",
                                }}
                              >
                                <Flex alignItems={"center"}>
                                  <Box ml={"-34px"} mr={"4px"}>
                                    <img
                                      src={icon}
                                      alt={icon}
                                      style={{
                                        width: 20,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    flex={1}
                                    textAlign={"left"}
                                    display={
                                      navSize === "small" ? "none" : "flex"
                                    }
                                  >
                                    <Text isTruncated fontSize="13px" ml="1">
                                      {title}
                                    </Text>
                                    <AccordionIcon color="gray.100" />
                                  </Box>
                                </Flex>
                              </AccordionButton>
                              <AccordionPanel pt={0} px={"6px"}>
                                {subMenuItem &&
                                  subMenuItem.map(
                                    ({ id, icon, title, url, hidden }) => (
                                      <>
                                        {!hidden && (
                                          <Link to={url} key={`children ${id}`}>
                                            <Flex
                                              color="gray.100"
                                              alignItems="center"
                                              w="full"
                                              p={"2px"}
                                              px={"4px"}
                                              cursor="pointer"
                                              borderRadius="md"
                                              bgColor={getBgColor(id)}
                                              _hover={{
                                                backgroundColor: "blue.400",
                                              }}
                                              mt={"6px"}
                                            >
                                              <img src={icon} alt={icon} />
                                              <Text
                                                isTruncated
                                                fontSize="13px"
                                                px={"4px"}
                                              >
                                                {title}
                                              </Text>
                                            </Flex>
                                          </Link>
                                        )}
                                      </>
                                    )
                                  )}
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        </Box>
                      </Flex>
                    </>
                  );
                } else {
                  if (!hidden) {
                    if (onClickMenu) {
                      return (
                        <Flex
                          color="gray.100"
                          p={"6px"}
                          cursor="pointer"
                          borderRadius="md"
                          bgColor={getBgColor(id)}
                          _hover={{
                            backgroundColor: "blue.400",
                          }}
                          mt={"4px"}
                          onClick={() => handleRedirectOnClick(onClickMenu)}
                        >
                          {
                            <img
                              src={icon}
                              alt={icon}
                              width={"18px"}
                              height={"18px"}
                            />
                          }
                          {navSize === "large" && (
                            <Text ml={"12px"} fontSize="13px">
                              {title}
                            </Text>
                          )}
                        </Flex>
                      );
                    } else {
                      return (
                        <Link to={url} key={`item ${id}`}>
                          <Flex
                            justifyContent="space-between"
                            color="gray.100"
                            p={"6px"}
                            cursor="pointer"
                            borderRadius="md"
                            bgColor={getBgColor(id)}
                            _hover={{
                              backgroundColor: "blue.400",
                            }}
                            mt={"6px"}
                          >
                            <Flex alignItems={"center"}>
                              <img
                                src={icon}
                                alt={icon}
                                width={"18px"}
                                height={"18px"}
                              />

                              {oldIcon}

                              {navSize === "large" && (
                                <Text
                                  isTruncated
                                  fontSize={"13px"}
                                  ml={"12px"}
                                  mt={"4px"}
                                >
                                  {title}
                                </Text>
                              )}
                            </Flex>

                            {isNew && navSize !== "small" && (
                              <Center
                                bg="linear-gradient(180deg, #F9D71C 0%, #f9b93d 100%);"
                                fontSize="0.8em"
                                fontWeight={"light"}
                                color="newBlue.500"
                                w={"32px"}
                                h={"18px"}
                                mt={"4px"}
                                borderRadius={4}
                                isTruncated
                              >
                                {t("new")}
                              </Center>
                            )}
                          </Flex>
                        </Link>
                      );
                    }
                  }
                }
              }
            )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default observer(NavBar);
