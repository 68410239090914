import { observer } from "mobx-react-lite";
import { Tooltip } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useStores } from "../../../stores/setup/use-store";
import Timeline from "../../../components/Timeline/Timeline";
import { convertNumberIntervalToTimestamp } from "../../../util/convertHours/convertNumberIntervalToTimestamp";
import { generateFormattedTimelineDates } from "../../../util/timeline/generateFormattedTimelineDates";
import { formatterHourTooltip } from "../../../util/timeline/formatterHourTooltip";
import { DateTime } from "luxon";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { roundToNearestHour } from "../../../util/roundHours/roundHours";
import { useTranslation } from "react-i18next";
import { UserExperience } from "../../../enum/UserExperience";

interface ForecastChartInsightTimelineProps {}

const ForecastChartInsightTimeline: React.FC<ForecastChartInsightTimelineProps> =
  () => {
    const {
      dataStores: {
        forecastChartInsightStore,
        forecastChartMeteogramStore,
        forecastChartPageStore,
        regionStore,
      },
    } = useStores();

    const { t } = useTranslation("translation", {
      keyPrefix: "forecastChart.forecastChartInsightTimeline",
    });

    useEffect(
      () => {
        if (regionStore.mainRegionTimezoneOffset === null) return;

        const now = roundToNearestHour(DateTime.now().toMillis());
        forecastChartInsightStore.setTimelineDate({
          start: now.toMillis(),
          end: now.plus({ day: 1 }).toMillis(),
          timezoneOffset: regionStore.mainRegionTimezoneOffset,
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        regionStore.mainRegionTimezoneOffset,
        forecastChartMeteogramStore.selectedInsight,
      ]
    );

    const formattedDates = useMemo(() => {
      if (!forecastChartInsightStore.timelineDate) return;
      const dateFormat = "dd/MM";

      return generateFormattedTimelineDates(
        forecastChartInsightStore.timelineDate?.start,
        forecastChartInsightStore.timelineDate?.end,
        forecastChartInsightStore.timelineDate.timezoneOffset,
        dateFormat,
        6
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forecastChartInsightStore.timelineDate]);

    const generateMarks = (formattedDates: string[]) => {
      const marksObject: { [key: number]: any } = {};

      const list: number[] = [0, 6, 12, 18, 24];

      list.forEach((hour, index) => {
        marksObject[hour] = {
          style: { fontSize: "12px" },
          label: (
            <span style={{ whiteSpace: "nowrap" }}>
              {formattedDates[index]}
            </span>
          ),
        };
      });

      return marksObject;
    };

    const marks = useMemo(() => {
      if (!formattedDates || !formattedDates.length) return;

      return generateMarks(formattedDates);
    }, [formattedDates]);

    const formatter = useCallback(
      (value?: number) => {
        if (!forecastChartInsightStore.timelineDate || value === undefined) {
          return;
        }

        return formatterHourTooltip(
          value,
          forecastChartInsightStore.timelineDate.start,
          forecastChartInsightStore.timelineDate.timezoneOffset
        );
      },
      [forecastChartInsightStore.timelineDate]
    );

    const onAfterChange = useCallback(
      (value: number[]) => {
        if (!forecastChartInsightStore.timelineDate) return;
        if (regionStore.mainRegionTimezoneOffset === null) return;
        const [startDate, endDate] = convertNumberIntervalToTimestamp(
          value,
          forecastChartInsightStore.timelineDate.start,
          forecastChartInsightStore.timelineDate.end
        );

        forecastChartPageStore.addLogUserExperienceAPI(
          UserExperience.CLICK_GEOESPATIAL_TIMELINE,
          JSON.stringify({ startTimestamp: startDate, endTimestamp: endDate })
        );

        forecastChartInsightStore.setSelectedTimelineDate({
          start: startDate,
          end: endDate,
          timezoneOffset: forecastChartInsightStore.timelineDate.timezoneOffset,
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [forecastChartInsightStore]
    );

    const formatIntervalSubtitle = useMemo(() => {
      if (!forecastChartInsightStore.timelineDate) {
        return "";
      }

      const dateFormat = "dd/MM HH'h'";

      if (!forecastChartInsightStore.selectedTimelineDate) {
        const { start } = forecastChartInsightStore.timelineDate;
        const roundStart = roundToNearestHour(start).toMillis();

        const startDate = DateTime.fromMillis(roundStart)
          .toUTC(forecastChartInsightStore.timelineDate.timezoneOffset * -1)
          .plus({ hours: 0 })
          .toFormat(dateFormat);

        const endDate = DateTime.fromMillis(roundStart)
          .toUTC(forecastChartInsightStore.timelineDate.timezoneOffset * -1)
          .plus({ hours: 3 })
          .toFormat(dateFormat);

        const interval = `${startDate} - ${endDate}`;
        forecastChartInsightStore.setTimelineInterval(interval);

        return `${startDate} - ${endDate}`;
      }

      const { start, end } = forecastChartInsightStore.selectedTimelineDate;

      const roundStart = roundToNearestHour(start).toMillis();
      const roundEnd = roundToNearestHour(end).toMillis();

      const startDate = DateTime.fromMillis(roundStart)
        .toUTC(forecastChartInsightStore.timelineDate.timezoneOffset * -1)
        .toFormat(dateFormat);

      const endDate = DateTime.fromMillis(roundEnd)
        .toUTC(forecastChartInsightStore.timelineDate.timezoneOffset * -1)
        .toFormat(dateFormat);

      const interval = `${startDate} - ${endDate}`;
      forecastChartInsightStore.setTimelineInterval(interval);

      return `${startDate} - ${endDate}`;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      forecastChartInsightStore.selectedTimelineDate,
      forecastChartInsightStore.timelineDate,
    ]);

    if (!forecastChartInsightStore.timelineDate) {
      return <></>;
    }

    return (
      <Timeline
        className="joyride-timeline"
        marks={marks}
        onAfterChange={onAfterChange}
        title={t("swapToSetRanges") as string}
        titleStyle={{ color: "#455154", fontSize: "12px" }}
        subtitle={formatIntervalSubtitle}
        subtitleStyle={{ color: "#286795", fontSize: "12px" }}
        icon={
          <Tooltip
            label={t("useTheCursorToAdjustTime")}
            fontSize="sm"
            placement="top-start"
            hasArrow
          >
            <InfoOutlinedIcon style={{ color: "#6A7578" }} fontSize="small" />
          </Tooltip>
        }
        sliderDefaultValue={[0, 3]}
        sliderTooltip={{
          formatter,
          color: "#286795",
          placement: "top",
        }}
        theme={{
          components: {
            Slider: {
              handleColor: "#286795",
              handleActiveColor: "#2875AE",
            },
          },
        }}
        sliderStyles={{
          track: { background: "#286795" },
          rail: { background: "#C9CFD1" },
        }}
        containerStyle={{
          background: "white",
          color: "#286795",
          position: "absolute",
          justifySelf: "center",
          borderRadius: "8px",
          marginTop: "8px",
          zIndex: 9999999,
          paddingLeft: 32,
          paddingRight: 32,
        }}
        slideContainerStyle={{
          paddingInlineStart: "8px",
          paddingInlineEnd: "8px",
          width: "435px",
          height: "60px",
          marginTop: "-5px",
        }}
        boxContainerStyle={{
          paddingTop: "10px",
          height: "75px",
        }}
      />
    );
  };

export default observer(ForecastChartInsightTimeline);
