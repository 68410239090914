import { SelectProps } from "antd";
import axios, { CancelToken } from "axios";
import { makeAutoObservable } from "mobx";
import { getEnv, getRoot } from "mobx-easy";
import RootStore from "stores/root-store";
import { StoreState } from "../../../../enum/StoreState";
import { UserExperience } from "../../../../enum/UserExperience";
import {
  IInsightMultiStation,
  IInsightStationData,
} from "../../../../services/OceanWeatherIntelligenceService";
import {
  Coordinate,
  extractCoordinates,
  getCoordinatesWithinRadius,
} from "../../../../util/getCoordinatesWithinRadius/getCoordinatesWithinRadius";
import { RootEnv } from "../../../setup/create-store";
import { IStation } from "../../oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import {
  IGetAllInsightsParams,
  IOperationInsightDescription,
} from "../../operationRuleLocationInsights/operationRuleLocationInsightModel";

interface timelinePeriod {
  start: number;
  end: number;
  timezoneOffset: number;
}
export default class ForecastChartInsightStore {
  state: string = StoreState.DONE;
  stateInsightList: string = StoreState.DONE;
  stateFavorite: string | null = null;
  insightList: IOperationInsightDescription[] = [];
  filteredInsightList: IOperationInsightDescription[] = [];
  insightMultiStation: IInsightMultiStation | null = null;
  selectedCoordinatesWithinRadius: Coordinate[] = [];
  radius: number = 18;
  hasSomeInsightWithoutData = false;
  showInsightWithoutDataToast = true;
  timelineDate: timelinePeriod | null = null;
  selectedTimelineDate: timelinePeriod | null = null;
  timelineInterval: string = "";
  favoriteInsight: string | null = null;
  favoriteInsightId: number | null = null;
  loadingInsightMultiStation: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setStateInsightList(value: StoreState) {
    this.stateInsightList = value;
  }

  setStateFavorite(value: StoreState | null) {
    this.stateFavorite = value;
  }

  setInsightList(value: IOperationInsightDescription[]) {
    this.insightList = value;
  }

  setInsightMultiStation(value: IInsightMultiStation | null) {
    this.insightMultiStation = value;
  }

  setSelectedCoordinatesWithinRadius(value: Coordinate[]) {
    this.selectedCoordinatesWithinRadius = value;
  }

  setHasSomeInsightWithoutData(value: boolean) {
    this.hasSomeInsightWithoutData = value;
  }

  setShowInsightWithoutDataToast(value: boolean) {
    this.showInsightWithoutDataToast = value;
  }

  setTimelineDate(value: timelinePeriod | null) {
    this.timelineDate = value;
  }

  setSelectedTimelineDate(value: timelinePeriod | null) {
    this.selectedTimelineDate = value;
  }

  setTimelineInterval(value: string) {
    this.timelineInterval = value;
  }

  setFavoriteInsight(value: string | null) {
    this.favoriteInsight = value;
  }

  setFavoriteInsightId(value: number | null) {
    this.favoriteInsightId = value;
  }

  setFilteredInsightList(value: IOperationInsightDescription[]) {
    this.filteredInsightList = value;
  }

  setLoadingInsightMultiStation(value: boolean) {
    this.loadingInsightMultiStation = value;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setStateInsightList(StoreState.DONE);
    this.setInsightList([]);
    this.setFilteredInsightList([]);
    this.setSelectedCoordinatesWithinRadius([]);
    this.setInsightMultiStation(null);
    this.setHasSomeInsightWithoutData(false);
    this.setShowInsightWithoutDataToast(true);
    this.setFavoriteInsight(null);
    this.setFavoriteInsightId(null);
  }

  resetMultiStationInsight() {
    this.setInsightMultiStation(null);
    this.setSelectedCoordinatesWithinRadius([]);
    this.setTimelineDate(null);
    this.setSelectedTimelineDate(null);
    this.setTimelineInterval("");
  }

  async getInsightMultiStation(
    operationRuleLocationItemId: number,
    startTimestamp: number,
    endTimestamp: number,
    stations: { stationId: number; regionId: number }[],
    cancelToken?: CancelToken
  ) {
    const { oceanWeatherIntelligenceService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    this.setLoadingInsightMultiStation(true);
    try {
      const data = await oceanWeatherIntelligenceService.getInsightMultiStation(
        operationRuleLocationItemId,
        startTimestamp,
        endTimestamp,
        stations,
        cancelToken
      );

      if (!this.insightMultiStation) {
        this.setInsightMultiStation(data);
      } else {
        const insightMultiStationCopy = { ...this.insightMultiStation };
        for (const stationData of data.stationsData) {
          const foundIndex = insightMultiStationCopy.stationsData.findIndex(
            (s) =>
              s.stationId === stationData.stationId &&
              s.regionId === stationData.regionId
          );
          if (foundIndex >= 0) {
            insightMultiStationCopy.stationsData[foundIndex] = stationData;
          } else {
            insightMultiStationCopy.stationsData.push(stationData);
          }
        }
        this.setInsightMultiStation(insightMultiStationCopy);
      }

      const hasDataEmpty = data.stationsData.some((i) => !i?.innerCircle);

      this.setHasSomeInsightWithoutData(hasDataEmpty);

      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      if (!axios.isCancel(error)) {
        this.setInsightMultiStation(null);
      }
    } finally {
      this.setLoadingInsightMultiStation(false);
    }
  }

  async getAllOperationRuleLocationInsight({
    limit,
    filter,
    macroRegionOrigin,
  }: IGetAllInsightsParams) {
    const { operationRuleLocationInsightService } = getEnv<RootEnv>();

    this.setStateInsightList(StoreState.PEDDING);
    this.setState(StoreState.PEDDING);

    this.setFavoriteInsight(null);
    this.setFavoriteInsightId(null);
    this.setFilteredInsightList([]);
    try {
      const data =
        await operationRuleLocationInsightService.getAllOperationRuleLocationInsight(
          {
            limit,
            filter,
            macroRegionOrigin,
          }
        );

      if (filter) {
        this.setFilteredInsightList(data);
      } else {
        this.setInsightList(data);
      }

      const favoriteInsight = data?.find((d) => d.favorite);
      if (favoriteInsight) {
        const { id, operationRuleName, operationRuleLocationName, name } =
          favoriteInsight;

        const formattedInsight = `${id}-${operationRuleName}-${operationRuleLocationName}-${name}`;
        this.setFavoriteInsight(formattedInsight);
        this.setFavoriteInsightId(id);
      }
      this.setStateInsightList(StoreState.DONE);
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setStateInsightList(StoreState.ERROR);
      this.setState(StoreState.ERROR);
    }
  }

  findStationsRegionsByCoordinates(
    stations: IStation[]
  ): { stationId: number; regionId: number }[] {
    if (!this.selectedCoordinatesWithinRadius.length || !stations.length)
      return [];

    const selectedStations: { stationId: number; regionId: number }[] = [];

    for (const station of stations) {
      for (const coord of this.selectedCoordinatesWithinRadius) {
        if (
          coord.latitude === station.station_lat &&
          coord.longitude === station.station_lon
        ) {
          selectedStations.push({
            stationId: station.station_id,
            regionId: station.region_id || 0,
          });
        }
      }
    }

    return selectedStations;
  }

  getInsightListForSelectOptions() {
    const options: SelectProps["options"] = [];

    const insights = this.filteredInsightList.length
      ? this.filteredInsightList
      : this.insightList;

    for (const insight of insights) {
      options.push({
        label: insight.name,
        value: `${insight.id}-${insight.operationRuleName}-${insight.operationRuleLocationName}-${insight.name}`,
        desc: `${insight.operationRuleName} - ${insight.operationRuleLocationName}`,
      });
    }

    return options;
  }

  findClosestInsightStationDate(stationData: IInsightStationData[]) {
    if (!stationData?.length) {
      return null;
    }

    let closestDate = stationData[0];
    let minDifference = Math.abs(closestDate.timestamp - Date.now());

    for (let i = 1; i < stationData.length; i++) {
      const difference = Math.abs(stationData[i].timestamp - Date.now());
      if (difference < minDifference) {
        minDifference = difference;
        closestDate = stationData[i];
      }
    }

    return closestDate;
  }

  calculateCoordinatesWithinRadius(
    stationCoordinates: Coordinate,
    stations: IStation[]
  ) {
    const coordinatesList = getCoordinatesWithinRadius(
      stationCoordinates,
      extractCoordinates(stations),
      this.radius
    );

    this.setSelectedCoordinatesWithinRadius(coordinatesList);
  }

  getInsightColorByInterval(
    data: IInsightStationData[],
    startDate: number,
    endDate: number
  ) {
    const dataFiltered = data.filter(
      (item) => item.timestamp >= startDate && item.timestamp <= endDate
    );

    if (!dataFiltered?.length) {
      return;
    }

    const red = "#C83F37";
    const yellow = "#E0A32D";
    const green = "#1ABC57";

    const isRed = dataFiltered.some((x) => x.color === red);

    if (isRed) {
      return red;
    }

    const isYellow = dataFiltered.some((x) => x.color === yellow);

    if (isYellow) {
      return yellow;
    }

    return green;
  }

  async addOperationRuleLocationItemAsFavorite(
    insight: IOperationInsightDescription
  ) {
    const { operationRuleLocationInsightService } = getEnv<RootEnv>();
    const {
      dataStores: { forecastChartPageStore, forecastChartInsightStore },
    } = getRoot<RootStore>();
    this.setStateFavorite(StoreState.PEDDING);
    try {
      const formattedInsight = `${insight.id}-${insight.operationRuleName}-${insight.operationRuleLocationName}-${insight.name}`;

      forecastChartPageStore.setFavoriteInsightAlreadyLoaded(true);
      forecastChartInsightStore.setFavoriteInsight(formattedInsight);
      forecastChartInsightStore.setFavoriteInsightId(insight.id);

      const result =
        await operationRuleLocationInsightService.addOperationRuleLocationItemAsFavorite(
          insight.id
        );

      if (result.success) {
        this.setStateFavorite(StoreState.DONE);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("axios request cancelled");
      } else {
        this.setFavoriteInsight(null);
        this.setFavoriteInsightId(null);
        this.setStateFavorite(StoreState.ERROR);
      }
    }
  }

  async deleteOperationRuleLocationItemAsFavorite(favoriteId: number) {
    const { operationRuleLocationInsightService } = getEnv<RootEnv>();
    this.setStateFavorite(StoreState.PEDDING);
    try {
      const result =
        await operationRuleLocationInsightService.deleteOperationRuleLocationItemAsFavorite(
          favoriteId
        );

      if (result.success) {
        this.setFavoriteInsight(null);
        this.setFavoriteInsightId(null);

        this.setStateFavorite(StoreState.DONE);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("axios request cancelled");
      } else {
        this.setStateFavorite(StoreState.ERROR);
      }
    }
  }

  async addLogUserExperienceAPI(userExperience: UserExperience) {
    const { logServices } = getEnv<RootEnv>();
    logServices.addLogUserExperience(userExperience);
  }
}
