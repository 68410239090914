import { EnvironmentalType } from "enum/EnvironmentalType";
import { ISensorMeasuredData, MeasuredData } from "types/ISensorMeasuredData";
import { SensorMarker } from "components/SensorMarker/SensorMarker";
import { EnvironmentalVariables } from "enum/EnvironmentalVariables";
import arrowIcon from "assets/marker-icon.svg";
import warningClock from "assets/warning-clock.svg";

interface SensorMarkerIconProps {
  sensorItem: ISensorMeasuredData;
  isSelected: boolean;
  tabStateActivity: EnvironmentalType;
}

const SensorMarkerIcon = ({
  sensorItem,
  isSelected,
  tabStateActivity,
}: SensorMarkerIconProps) => {
  const getEnvironmentalData = (
    measuredData: MeasuredData[],
    directionVar: EnvironmentalVariables,
    valueVar: EnvironmentalVariables
  ) => {
    const directionData = measuredData.find(
      (item) => item.environmental_variable === directionVar
    );
    const variableData = measuredData.find(
      (item) =>
        item.environmental_variable.includes(valueVar) &&
        item.environmental_variable !== EnvironmentalVariables.WAVE_HEIGHT_MAX
    );

    return { directionData, variableData };
  };

  const getMeasuredDataByActivityTab = (
    sensorItem?: ISensorMeasuredData,
    tabStateActivity?: EnvironmentalType
  ) => {
    if (!sensorItem)
      return {
        directionData: undefined,
        variableData: undefined,
      };

    const { measuredData } = sensorItem;

    let environmentalData;

    switch (tabStateActivity) {
      case EnvironmentalType.WEATHER:
        environmentalData = getEnvironmentalData(
          measuredData,
          EnvironmentalVariables.WIND_DIR,
          EnvironmentalVariables.WIND_VEL
        );
        break;
      case EnvironmentalType.WAVE:
        environmentalData = getEnvironmentalData(
          measuredData,
          EnvironmentalVariables.WAVE_DIR,
          EnvironmentalVariables.WAVE_HEIGHT
        );
        break;
      case EnvironmentalType.WATER_FLOW:
        environmentalData =
          sensorItem.environmentalType === EnvironmentalType.CURRENT
            ? getEnvironmentalData(
                measuredData,
                EnvironmentalVariables.CURRENT_DIR,
                EnvironmentalVariables.CURRENT_VEL
              )
            : getEnvironmentalData(
                measuredData,
                EnvironmentalVariables.CURRENT_DIR,
                EnvironmentalVariables.WATER_LEVEL
              );
        break;
      default:
        environmentalData = getEnvironmentalData(
          measuredData,
          EnvironmentalVariables.WIND_DIR,
          EnvironmentalVariables.WIND_VEL
        );
        break;
    }

    return environmentalData;
  };

  const { directionData, variableData } = getMeasuredDataByActivityTab(
    sensorItem,
    tabStateActivity
  );

  let icon = !!directionData
    ? arrowIcon
    : !!variableData
    ? undefined
    : warningClock;

  return (
    <SensorMarker
      degrees={directionData?.value ?? 0}
      value={variableData?.value ?? 0}
      defaultUnit={variableData?.units}
      environmentalVariable={variableData?.environmental_variable}
      isSelected={isSelected}
      markerIcon={icon}
    />
  );
};

export default SensorMarkerIcon;
