import { TooltipContent } from "components/TooltipComponent/ToolltipComponent";
import { LAST_SELECTED_STATION_KEY } from "../../../../constants";
import { VisualizationType } from "enum/VisualizationType";
import { divIcon, LeafletMouseEvent } from "leaflet";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { Marker, Tooltip } from "react-leaflet";
import { useStores } from "stores/setup/use-store";
import { ISensorMeasuredData, MeasuredData } from "types/ISensorMeasuredData";
import { MapMarkerChildProps } from "../ForecastChartPanelMap";
import { EnvironmentalType } from "enum/EnvironmentalType";
import { EnvironmentalVariables } from "enum/EnvironmentalVariables";
import {
  findChartMenuParentId,
  loadChartMenuData,
} from "pages/ForecastChart/ForecastChartPanelEnvironmentalButtons/ChartMenuConfig";
import { IStation } from "stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import PulseLoadingMarker from "components/PulseLoadingMarker/PulseLoadingMarker";
import { renderToStaticMarkup } from "react-dom/server";
import SensorMarkerIcon from "./SensorMarkerIcon";
import markerOff from "../../../../assets/marker-off.svg";
import markerOffSelected from "../../../../assets/marker-off-selected.svg";
import arrowIcon from "assets/marker-icon.svg";
import warningClock from "assets/warning-clock.svg";
import { SensorMarker } from "components/SensorMarker/SensorMarker";
import { useTranslation } from "react-i18next";

const CustomMarker = memo((props: MapMarkerChildProps) => {
  const {
    dataStores: {
      forecastChartPageStore,
      regionStore,
      forecastChartInsightStore,
      forecastChartMeteogramStore,
      forecastChartProbabilisticStore,
      analyticsChartStore,
    },
  } = useStores();

  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMap",
  });

  const { station } = props;
  const { station_id, station_name, station_lat, station_lon, region_id } =
    station;

  const markerRef = useRef<L.Marker>(null);

  useEffect(() => {
    if (markerRef.current) {
      //@ts-ignore
      markerRef.current.options.station = station;
    }
  }, []);

  const isInsightStationEmpty = useMemo(() => {
    const matchingStation =
      forecastChartInsightStore.insightMultiStation?.stationsData.find(
        (data) =>
          data.stationId === station.station_id &&
          data.regionId === station.region_id
      );

    if (matchingStation && !matchingStation.innerCircle) {
      return true;
    }

    return false;
  }, [station]);

  const sensorItem = forecastChartPageStore.sensorMarkerData?.find(
    (marker) =>
      marker.station_id === station.station_id &&
      marker.region_id === station.region_id
  );

  const handleMarkerClick = useCallback(
    (e: LeafletMouseEvent) => {
      forecastChartMeteogramStore.setOpenMeteogram(true);
      analyticsChartStore.setIsLastChartDateGreaterThan3Hours(false);

      if (e.latlng === forecastChartPageStore.clickMapLatLng) {
        return;
      }
      forecastChartMeteogramStore.resetInsightMeteogram();
      forecastChartProbabilisticStore.reset();

      forecastChartPageStore.setSelectedStation(station_id);
      forecastChartPageStore.setRegionId(region_id as number);
      forecastChartPageStore.setClickMapLatLng(e.latlng);
      forecastChartPageStore.setStationInfo();
      forecastChartPageStore.updateMacroRegionOrigin(
        e.latlng,
        regionStore.macroRegions
      );
      forecastChartPageStore.setActionCallMapClick();

      station &&
        localStorage.setItem(
          LAST_SELECTED_STATION_KEY,
          JSON.stringify(station)
        );

      if (
        forecastChartMeteogramStore.visualizationType ===
          VisualizationType.SENSOR &&
        sensorItem
      ) {
        forecastChartPageStore.setActionCallGetStationsWithSensor();
      }

      const stationCoordinates = {
        latitude: station.station_lat,
        longitude: station.station_lon,
      };

      forecastChartInsightStore.calculateCoordinatesWithinRadius(
        stationCoordinates,
        forecastChartPageStore.stations
      );
    },
    [region_id, station.station_lat, station.station_lon, station_id]
  );

  const getEnvironmentalData = (
    measuredData: MeasuredData[],
    directionVar: EnvironmentalVariables,
    valueVar: EnvironmentalVariables
  ) => {
    const directionData = measuredData.find(
      (item) => item.environmental_variable === directionVar
    );
    const variableData = measuredData.find(
      (item) =>
        item.environmental_variable.includes(valueVar) &&
        item.environmental_variable !== EnvironmentalVariables.WAVE_HEIGHT_MAX
    );

    return { directionData, variableData };
  };

  const renderPulseLoadingMarker = () => {
    return divIcon({
      html: renderToStaticMarkup(<PulseLoadingMarker />),
    });
  };

  const sensorMarkerIcon = (
    sensorItem: ISensorMeasuredData,
    isSelected: boolean
  ) => {
    return divIcon({
      html: renderToStaticMarkup(
        <SensorMarkerIcon
          sensorItem={sensorItem}
          isSelected={isSelected}
          tabStateActivity={forecastChartPageStore.tabStateActivty}
        />
      ),
    });
  };

  const noDataInsightMarker = divIcon({
    html: renderToStaticMarkup(<img src={markerOff} alt="marker off" />),
  });

  const noDataInsightMarkerSelected = divIcon({
    html: renderToStaticMarkup(
      <img src={markerOffSelected} alt="marker off selected" />
    ),
  });

  const customMarkerIconSelected = divIcon({
    html: renderToStaticMarkup(
      <i style={{ color: "#2E3233" }} className="fas fa-map-marker fa-2x" />
    ),
  });

  const customMarkerIconWithData = divIcon({
    html: renderToStaticMarkup(
      <i style={{ color: "#1890ff" }} className="fas fa-map-marker-alt fa-1x" />
    ),
  });

  const customMarkerIconSelectedWithData = divIcon({
    html: renderToStaticMarkup(
      <i style={{ color: "#1890ff" }} className="fas fa-map-marker fa-2x" />
    ),
  });

  const customMarkerIcon = (isSensorTab = false) => {
    return divIcon({
      html: renderToStaticMarkup(
        <i
          style={{ color: `rgb(46, 50, 51, ${isSensorTab ? 0.5 : 1})` }}
          className="fas fa-map-marker-alt fa-1x"
        />
      ),
    });
  };

  const renderInsightMarker = ({
    innerColor,
    outerColor,
    size,
    isSensorTab,
    sensorItem,
  }: {
    innerColor: string;
    outerColor: string;
    size: "small" | "large";
    hasSensor: boolean;
    isSensorTab: boolean;
    sensorItem?: ISensorMeasuredData | undefined;
  }) => {
    const isLarge = size === "large";

    const { directionData, variableData } =
      getMeasuredDataByActivityTab(sensorItem);

    let icon = !!directionData
      ? arrowIcon
      : !!variableData
      ? undefined
      : warningClock;

    return divIcon({
      html: renderToStaticMarkup(
        <div style={{ position: "relative" }}>
          {isSensorTab && sensorItem && (
            <div style={{ position: "absolute", inset: 0, zIndex: 10 }}>
              <SensorMarker
                degrees={directionData?.value ?? 0}
                value={variableData?.value ?? 0}
                defaultUnit={variableData?.units}
                environmentalVariable={variableData?.environmental_variable}
                isSelected={false}
                markerIcon={icon}
              />
            </div>
          )}

          <span
            className={`fa-stack fa ${size}`}
            style={{ opacity: isSensorTab ? 0.6 : 1 }}
          >
            <i
              style={{ color: outerColor }}
              className={`far fa-circle fa-stack-2x  ${isLarge && "fa-beat"}`}
            />
            <i
              style={{ color: innerColor }}
              className={`fa fa-circle fa-stack-1x fa-inverse ${
                isLarge && "fa-beat"
              }`}
            />
          </span>
        </div>
      ),
    });
  };

  const getMeasuredDataByActivityTab = (sensorItem?: ISensorMeasuredData) => {
    const { tabStateActivty } = forecastChartPageStore;
    let environmentalData;
    if (!sensorItem)
      return {
        directionData: undefined,
        variableData: undefined,
      };

    const { measuredData } = sensorItem;

    switch (tabStateActivty) {
      case EnvironmentalType.WEATHER:
        environmentalData = getEnvironmentalData(
          measuredData,
          EnvironmentalVariables.WIND_DIR,
          EnvironmentalVariables.WIND_VEL
        );
        break;
      case EnvironmentalType.WAVE:
        environmentalData = getEnvironmentalData(
          measuredData,
          EnvironmentalVariables.WAVE_DIR,
          EnvironmentalVariables.WAVE_HEIGHT
        );
        break;
      case EnvironmentalType.WATER_FLOW:
        if (sensorItem.environmentalType === EnvironmentalType.CURRENT) {
          environmentalData = getEnvironmentalData(
            measuredData,
            EnvironmentalVariables.CURRENT_DIR,
            EnvironmentalVariables.CURRENT_VEL
          );
        } else {
          environmentalData = getEnvironmentalData(
            measuredData,
            EnvironmentalVariables.CURRENT_DIR,
            EnvironmentalVariables.WATER_LEVEL
          );
        }
        break;
      default:
        environmentalData = getEnvironmentalData(
          measuredData,
          EnvironmentalVariables.WIND_DIR,
          EnvironmentalVariables.WIND_VEL
        );
        break;
    }

    return environmentalData;
  };

  const stationName = isInsightStationEmpty
    ? `[${t("noInsightData")}] ${station_name}`
    : station_name;

  let directionData: MeasuredData | undefined = undefined;
  let variableData: MeasuredData | undefined = undefined;

  const isSensorTab =
    forecastChartMeteogramStore.visualizationType === VisualizationType.SENSOR;

  if (sensorItem && isSensorTab) {
    const data = getMeasuredDataByActivityTab(sensorItem);
    directionData = data.directionData;
    variableData = data.variableData;
  }

  const getIconForStation = useCallback(
    (station: IStation) => {
      const _data = loadChartMenuData();
      const parentMenu = findChartMenuParentId(
        _data,
        forecastChartPageStore.tabStateActivty
      );

      const sensorItem = forecastChartPageStore.sensorMarkerData?.find(
        (marker) =>
          marker.station_id === station.station_id &&
          marker.region_id === station.region_id
      );

      const isSensorTab =
        forecastChartMeteogramStore.visualizationType ===
        VisualizationType.SENSOR;

      if (forecastChartPageStore.insightMode) {
        const selectedCoordinatesWithinRadiusMapper: Record<number, number> =
          forecastChartInsightStore.selectedCoordinatesWithinRadius.reduce(
            (acc, curr) => {
              return { ...acc, [curr.latitude]: curr.longitude };
            },
            {}
          );

        if (forecastChartInsightStore.loadingInsightMultiStation) {
          const isSameLat =
            station.station_lat in selectedCoordinatesWithinRadiusMapper;
          const isSameLong =
            selectedCoordinatesWithinRadiusMapper[station.station_lat] ===
            station.station_lon;
          if (isSameLat && isSameLong) {
            return renderPulseLoadingMarker();
          }
        }

        const insightStation =
          forecastChartInsightStore.insightMultiStation?.stationsData.find(
            (s) =>
              s.stationId === station.station_id &&
              s.regionId === station.region_id
          );
        const isSelected =
          station.station_id === forecastChartPageStore.selectedStation &&
          station.region_id === forecastChartPageStore.regionId;

        if (!insightStation) {
          if (isSensorTab && !!sensorItem) {
            const isSelected =
              sensorItem.station_id ===
                forecastChartPageStore.selectedStation &&
              sensorItem.region_id === forecastChartPageStore.regionId;

            return sensorMarkerIcon(sensorItem, isSelected);
          }
          if (
            (forecastChartPageStore.tabStateActivty ===
              EnvironmentalType.WAVE ||
              parentMenu === EnvironmentalType.WAVE) &&
            forecastChartPageStore.stationsWithDataPostProcessed.find(
              (stationWithData) =>
                stationWithData.station_id === station.station_id &&
                stationWithData.region_id === station.region_id
            )
          ) {
            if (
              station.station_id === forecastChartPageStore.selectedStation &&
              station.region_id === forecastChartPageStore.regionId
            ) {
              return customMarkerIconSelectedWithData;
            }

            return customMarkerIconWithData;
          } else {
            return customMarkerIcon(isSensorTab);
          }
        }

        if (!insightStation.innerCircle) {
          if (isSensorTab && !!sensorItem) {
            const isSelected =
              sensorItem.station_id ===
                forecastChartPageStore.selectedStation &&
              sensorItem.region_id === forecastChartPageStore.regionId;

            return sensorMarkerIcon(sensorItem, isSelected);
          }

          if (isSelected) {
            return noDataInsightMarkerSelected;
          } else {
            return noDataInsightMarker;
          }
        }

        const currentInsightStationColor = insightStation.innerCircle;

        if (!currentInsightStationColor) {
          return customMarkerIcon(isSensorTab);
        }

        const nextHoursInsightStationColor = insightStation.outerCircle;

        if (!nextHoursInsightStationColor) {
          return customMarkerIcon(isSensorTab);
        }

        return renderInsightMarker({
          innerColor: currentInsightStationColor,
          outerColor: nextHoursInsightStationColor,
          size: isSelected ? "large" : "small",
          hasSensor: !!sensorItem,
          isSensorTab,
          sensorItem,
        });
      }

      if (isSensorTab && !!sensorItem) {
        const isSelected =
          sensorItem.station_id === forecastChartPageStore.selectedStation &&
          sensorItem.region_id === forecastChartPageStore.regionId;

        return sensorMarkerIcon(sensorItem, isSelected);
      }

      if (
        forecastChartPageStore.stationsWithDataPostProcessed.find(
          (stationWithData) =>
            stationWithData.station_id === station.station_id &&
            stationWithData.region_id === station.region_id
        ) &&
        (forecastChartPageStore.tabStateActivty === EnvironmentalType.WAVE ||
          parentMenu === EnvironmentalType.WAVE)
      ) {
        if (
          station.station_id === forecastChartPageStore.selectedStation &&
          station.region_id === forecastChartPageStore.regionId
        ) {
          return customMarkerIconSelectedWithData;
        }

        return customMarkerIconWithData;
      }

      if (
        station.station_id === forecastChartPageStore.selectedStation &&
        station.region_id === forecastChartPageStore.regionId
      ) {
        return customMarkerIconSelected;
      }

      return customMarkerIcon(isSensorTab);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customMarkerIcon,
      customMarkerIconSelected,
      customMarkerIconSelectedWithData,
      customMarkerIconWithData,
      forecastChartInsightStore,
      forecastChartPageStore.insightMode,
      forecastChartPageStore.regionId,
      forecastChartPageStore.selectedStation,
      forecastChartPageStore.stationsWithDataPostProcessed,
      forecastChartPageStore.tabStateActivty,
      noDataInsightMarker,
      noDataInsightMarkerSelected,
      forecastChartInsightStore.selectedTimelineDate,
      forecastChartPageStore.sensorMarkerData,
    ]
  );

  const isDataDelayed = sensorItem && !variableData && !directionData;

  return (
    <Marker
      ref={markerRef}
      position={[station_lat, station_lon]}
      icon={getIconForStation(props.station)}
      eventHandlers={{
        click: handleMarkerClick,
      }}
    >
      <Tooltip>
        <TooltipContent
          zoomLevel={forecastChartPageStore.mapZoom}
          title={stationName}
          value={variableData?.value}
          defaultUnit={variableData?.units}
          degreePosition={directionData?.value}
          lastUpdate={variableData ? new Date(variableData?.date) : undefined}
          environmentalVariable={variableData?.environmental_variable}
          showFooterText={isDataDelayed}
        />
      </Tooltip>
    </Marker>
  );
});

export default CustomMarker;
