import { observer } from "mobx-react-lite";
import React, { useEffect, useCallback, useState, useMemo } from "react";
import I4castAlert from "../../../components/I4castAlert/I4castAlert";
import { StoreState } from "../../../enum/StoreState";
import { useStores } from "../../../stores/setup/use-store";

import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import ForecastChartPastForecast from "../ForecastChartPastForecast/ForecastChartPastForecast";
import {
  Text as TextChakra,
  Flex,
  Box,
  Center,
  ButtonGroup,
  Button,
  Tooltip,
  useDisclosure,
  useToast,
  IconButton,
  Alert,
  AlertIcon,
  Text,
} from "@chakra-ui/react";
import Lottie from "lottie-react";
import noData from "../../../assets/animations/no-data.json";
import error from "../../../assets/animations/error.json";
import CachedIcon from "@material-ui/icons/Cached";
import { useTranslation } from "react-i18next";
import ShareIcon from "@material-ui/icons/Share";
import { ShareFormModal } from "./Components/ShareFormModal";
import { IUserShare } from "../../../stores/data/userConfig/userConfigModal";
import { useLocation } from "react-router-dom";
import ForecastChartInsightLegend from "../ForecastChartInsightLegend/ForecastChartInsightLegend";
import Info from "../../../assets/info.svg";

import ForecastUpdatePopover from "../ForecastChartPage/components/ForecastUpdatePopover";
import CloseIcon from "@material-ui/icons/Close";
import { UserExperience } from "../../../enum/UserExperience";
import { SCREEN_HORIZONTAL_SPACING } from "../../../constants";
import useWindowObserver from "../../../hooks/useWindowObserver";
import ProbabilisticChartMock from "./Components/ProbabilisticChartMock";
import { getConfig } from "../../../config/app.config";
import { ConfigKey } from "../../../enum/ConfigKey";
import { VisualizationType } from "../../../enum/VisualizationType";
import VisualizationTypeMenu from "./Components/VisualizationTypeMenu";
import ForecastChart from "./Charts/ForecastChart";
import ProbabilisticChart from "./Charts/ProbabilisticChart";
import SensorChart from "./Charts/SensorChart";
import { Spinner } from "@chakra-ui/react";

import { getTimeDifference } from "util/getTimeDifference/getTimeDifference";
import { setIfJoyrideIsDoneInStorage } from "util/joyRideLocalStorage/joyRideLocalStorage";
import { JoyrideStorage } from "enum/JoyrideStorage";

import MetOceanReportModal from "../MetOceanReport/MetOceanReportModal";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { EnvironmentalVariables } from "enum/EnvironmentalVariables";
import RainChartMock from "./Components/RainChartMock";
import { EmptyChartWithContact } from "components/EmptyChartWithContact/EmptyChartWithContact";
import RadarLegend from "./Components/RadarLegend";
import VisibilityChartMock from "./Components/VisibilityChartMock";

interface ForecastChartPanelMeteogramProps {}

const ForecastChartPanelMeteogram: React.FC<ForecastChartPanelMeteogramProps> =
  () => {
    const [usersList, setUsersList] = useState<IUserShare[]>([]);
    const [showAllTutorials, setShowAllTutorials] = useState<boolean>(false);
    const location = useLocation();
    const toast = useToast();
    const toastId = "toastInfoNoDataId";

    const windowSize = useWindowObserver(".main-area");
    const isSmallScreen = windowSize.width <= 1300;

    const {
      dataStores: {
        forecastChartPageStore,
        forecastChartMeteogramStore,
        forecastChartInsightStore,
        unitMeasurementStore,
        userConfigStore,
        shareStore,
        forecastChartProbabilisticStore,
        analyticsChartStore,
      },
    } = useStores();
    const { isOpen } = useDisclosure({
      isOpen: forecastChartMeteogramStore.isOpenShareModal,
    });

    const { isOpen: isTabularConfigOpen, onOpen, onClose } = useDisclosure();

    const popoverDisclosure = useDisclosure();

    const getChartHeight = () => {
      if (windowSize.width < 480) {
        return `${windowSize.height * 0.461}px`;
      } else if (windowSize.width < 768) {
        return `${windowSize.height * 0.399}px`;
      } else if (windowSize.width < 1200) {
        return `${windowSize.height * 0.393}px`;
      } else {
        return `${windowSize.height * 0.376}px`;
      }
    };

    const getContainerHeight = () => {
      if (windowSize.width < 480) {
        return "46.1vh";
      } else if (windowSize.width < 768) {
        return "39.9vh";
      } else if (windowSize.width < 1200) {
        return "39.33vh";
      } else {
        return "37.66vh";
      }
    };

    const chartHeight = getChartHeight();
    const containerHeight = getContainerHeight();

    const { t, i18n } = useTranslation("translation", {
      keyPrefix: "forecastChart.forecastChartPanelMeteogram",
    });

    useEffect(() => {
      if (
        forecastChartMeteogramStore.visualizationType ===
        VisualizationType.PROBABILISTIC
      ) {
        forecastChartMeteogramStore.setShowMeteogram(false);
        forecastChartPageStore.setProbabilisticVariableByActivityTab();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forecastChartPageStore.tabStateActivty]);

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const open = params.get("open");
      shareStore.getStationsAPI(EnvironmentalType.WEATHER); // EnvironmentalType ignored by Backend!
      forecastChartInsightStore.reset();
      forecastChartPageStore.setInsightMode(false);
      forecastChartMeteogramStore.resetInsightMeteogram();
      forecastChartMeteogramStore.setOpenMeteogram(true);
      forecastChartPageStore.setProbabilisticActivityVariable(null);
      forecastChartMeteogramStore.setShowMeteogram(true);
      forecastChartProbabilisticStore.setIsChartDataEmpty(false);
      const userId = localStorage.getItem("userId");
      userConfigStore.getUsersApi().then(() => {
        if (userId) {
          const filteredUsers = userConfigStore.users.filter(
            (user) => user.id !== parseInt(userId)
          );
          setUsersList(filteredUsers);
        }
        if (!!open) {
          handleOpenNewsModal();
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (
        forecastChartMeteogramStore.selectedInsight &&
        forecastChartMeteogramStore.showInsightOnMeteogram &&
        forecastChartMeteogramStore.selectForecastDateAvailable === undefined
      ) {
        const { currentInsightMeteogramData, insightMeteogram } =
          forecastChartMeteogramStore;
        if (
          forecastChartMeteogramStore.allPositionsAreNull(
            currentInsightMeteogramData
          ) &&
          !forecastChartMeteogramStore.allPositionsAreNull(
            insightMeteogram?.data
          ) &&
          !toast.isActive(toastId)
        ) {
          toast({
            id: toastId,
            title: t("toViewTheInsightSelectALongerPeriod", {
              hours: forecastChartMeteogramStore.periodSelected,
            }),
            status: "info",
            position: "top",
            isClosable: true,
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forecastChartMeteogramStore.currentInsightMeteogramData]);

    useEffect(() => {
      const interval = setInterval(() => {
        handleRefreshChart();
      }, 10 * 60 * 1000);

      return () => clearInterval(interval);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLegendItems = useCallback(
      (isRestrictive: boolean) => {
        const items = [
          {
            color: isRestrictive ? "#1ABC57" : "#C83F37",
            label: t("noEvent"),
            description: t("insightOff"),
          },
          {
            color: "#E0A32D",
            label: t("warning"),
            description: t("insightCloseToBeingActivated"),
          },
          {
            color: isRestrictive ? "#C83F37" : "#1ABC57",
            label: t("withEvent"),
            description: t("insightOn"),
          },
        ];

        return items;
      },
      [t]
    );

    const handleChangeChartPeriod = useCallback(
      (period?: number) => {
        forecastChartMeteogramStore.getChartMeteogramDataByPeriod({
          environmental_type: forecastChartPageStore.tabStateActivty,
          periodInHours: period,
          create_at: forecastChartMeteogramStore.selectForecastDateAvailable,
        });
      },
      [forecastChartMeteogramStore, forecastChartPageStore.tabStateActivty]
    );

    const handleRefreshChart = useCallback(() => {
      if (
        forecastChartMeteogramStore.visualizationType ===
        VisualizationType.SENSOR
      ) {
        forecastChartPageStore.setActionCallGetStationsWithSensor();
      } else {
        forecastChartPageStore.setActionCallMeteogramChart(
          !forecastChartPageStore.actionCallMeteogramChart
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forecastChartMeteogramStore.visualizationType]);

    const noDataAvailable = useMemo(() => {
      return (
        (forecastChartMeteogramStore.state === StoreState.DONE &&
          forecastChartMeteogramStore.isChartDataEmpty) ||
        analyticsChartStore.isChartDataEmpty ||
        (forecastChartProbabilisticStore.state === StoreState.DONE &&
          forecastChartProbabilisticStore.isChartDataEmpty &&
          !forecastChartProbabilisticStore.showNoProbabilisticDataMessage)
      );
    }, [
      analyticsChartStore.isChartDataEmpty,
      forecastChartMeteogramStore.isChartDataEmpty,
      forecastChartMeteogramStore.state,
      forecastChartProbabilisticStore.isChartDataEmpty,
      forecastChartProbabilisticStore.showNoProbabilisticDataMessage,
      forecastChartProbabilisticStore.state,
    ]);

    const showErrorOnChart = useMemo(() => {
      return (
        (forecastChartMeteogramStore.state === StoreState.ERROR &&
          forecastChartMeteogramStore.isChartDataEmpty) ||
        forecastChartProbabilisticStore.state === StoreState.ERROR
      );
    }, [
      forecastChartMeteogramStore.isChartDataEmpty,
      forecastChartMeteogramStore.state,
      forecastChartProbabilisticStore.state,
    ]);

    const hasProbabilityData = useMemo(() => {
      const data = forecastChartProbabilisticStore.probabilisticForecastData;
      if (
        forecastChartProbabilisticStore.state === StoreState.DONE &&
        forecastChartProbabilisticStore.isChartDataEmpty &&
        (!data?.confidence50.length ||
          !data?.confidence80.length ||
          !data?.median?.length)
      ) {
        return false;
      }
      return true;
    }, [
      forecastChartProbabilisticStore.isChartDataEmpty,
      forecastChartProbabilisticStore.probabilisticForecastData,
      forecastChartProbabilisticStore.state,
    ]);

    const isLoading =
      forecastChartPageStore.state === StoreState.PEDDING ||
      forecastChartPageStore.stateStationsWithSensorData ===
        StoreState.PEDDING ||
      forecastChartMeteogramStore.state === StoreState.PEDDING ||
      analyticsChartStore.state === StoreState.PEDDING ||
      forecastChartProbabilisticStore.state === StoreState.PEDDING;

    const showNoProbabilisticDataMessage = useMemo(() => {
      const data = forecastChartProbabilisticStore.probabilisticForecastData;
      const shouldShowNoProbabilisticDataMessage =
        (data?.deterministic.length &&
          (!data?.confidence50.length ||
            !data?.confidence80.length ||
            !data?.median?.length)) ||
        forecastChartProbabilisticStore.showNoProbabilisticDataMessage;

      return (
        forecastChartMeteogramStore.visualizationType ===
          VisualizationType.PROBABILISTIC &&
        shouldShowNoProbabilisticDataMessage &&
        !isLoading
      );
    }, [
      forecastChartMeteogramStore.visualizationType,
      forecastChartProbabilisticStore.probabilisticForecastData,
      forecastChartProbabilisticStore.showNoProbabilisticDataMessage,
      isLoading,
    ]);

    const showForecast =
      unitMeasurementStore.getUnitMeasurementState === StoreState.DONE &&
      forecastChartMeteogramStore.chartMeteogramDataSeries?.length > 0 &&
      unitMeasurementStore.state === StoreState.DONE;

    const showSensor =
      !showErrorOnChart &&
      analyticsChartStore.chartMeteogramDataSeries?.length &&
      analyticsChartStore.state === StoreState.DONE;

    const showProbabilistic =
      hasProbabilityData &&
      forecastChartProbabilisticStore.probabilisticForecastData;

    const showVisibility = useMemo(() => {
      const isVisibility =
        forecastChartPageStore.tabStateActivty === EnvironmentalType.VISIBILITY;

      if (!isVisibility) {
        return false;
      }

      const companyId = localStorage.getItem("companyId") || "0";
      const isCli =
        companyId === "59" ||
        companyId === "48" ||
        companyId === "19" ||
        companyId === "2";

      return isCli;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      forecastChartMeteogramStore.chartMeteogramDataSeries,
      forecastChartPageStore.tabStateActivty,
    ]);

    const hasRainData = useMemo(() => {
      const isPrecipitation =
        forecastChartPageStore.tabStateActivty ===
        EnvironmentalType.PRECIPITATION;

      if (!isPrecipitation) {
        return false;
      }

      const data = forecastChartMeteogramStore.chartMeteogramDataSeries?.some(
        (x: any) =>
          x.environmentalVariable === EnvironmentalVariables.PRECIP_MIN_20KM
      );

      return Boolean(data);
    }, [
      forecastChartMeteogramStore.chartMeteogramDataSeries,
      forecastChartPageStore.tabStateActivty,
    ]);

    const showChart = useMemo(() => {
      const isPrecipitation =
        forecastChartPageStore.tabStateActivty ===
        EnvironmentalType.PRECIPITATION;

      const isVisibility =
        forecastChartPageStore.tabStateActivty === EnvironmentalType.VISIBILITY;

      const companyId = localStorage.getItem("companyId") || "0";
      const isCLI =
        companyId === "59" ||
        companyId === "48" ||
        companyId === "19" ||
        companyId === "2";

      const shouldShowForecast = isPrecipitation
        ? hasRainData && showForecast
        : isVisibility
        ? isCLI && showForecast
        : showForecast;

      return (
        !showNoProbabilisticDataMessage &&
        (showProbabilistic || shouldShowForecast || showSensor) &&
        !noDataAvailable
      );
    }, [
      noDataAvailable,
      showForecast,
      showNoProbabilisticDataMessage,
      showProbabilistic,
      showSensor,
      forecastChartPageStore.tabStateActivty,
      hasRainData,
    ]);

    const showVisualizationTypeText = useMemo(() => {
      return (
        !noDataAvailable &&
        !showErrorOnChart &&
        !showNoProbabilisticDataMessage &&
        forecastChartPageStore.state === StoreState.DONE &&
        forecastChartMeteogramStore.state === StoreState.DONE &&
        forecastChartProbabilisticStore.state === StoreState.DONE &&
        analyticsChartStore.state === StoreState.DONE
      );
    }, [
      noDataAvailable,
      showErrorOnChart,
      showNoProbabilisticDataMessage,
      forecastChartPageStore.state,
      forecastChartMeteogramStore.state,
      forecastChartProbabilisticStore.state,
      analyticsChartStore.state,
    ]);

    const renderRealInterval = () => {
      const _realInterval = forecastChartMeteogramStore.realInterval;

      if (_realInterval) {
        if (_realInterval >= 60) {
          if (_realInterval >= 120) {
            return _realInterval / 60 + t("hours");
          }
          return _realInterval / 60 + t("hour");
        } else {
          return _realInterval + t("min");
        }
      }
    };

    const handleOpenNewsModal = () => {
      forecastChartMeteogramStore.setOpenShareModal(true);
    };

    const handleCloseNewsModal = () => {
      forecastChartMeteogramStore.setOpenShareModal(false);
    };

    const handleRedirectToContact = () => {
      const url = getConfig(ConfigKey.REACT_APP_CONTACT_URL);

      window.open(url, "_blank");
    };

    const handleCloseMeteogram = () => {
      forecastChartMeteogramStore.setOpenMeteogram(false);
      forecastChartMeteogramStore.addLogUserExperienceAPI(
        UserExperience.CLOSE_METEOGRAM
      );

      const showMessage = localStorage.getItem("showMessageOnCloseMeteogram");

      if (showMessage) {
        return;
      }

      localStorage.setItem("showMessageOnCloseMeteogram", "true");
      toast({
        id: toastId,
        title: t("clickOnMapToOpen"),
        status: "info",
        position: "top",
        isClosable: true,
      });
    };

    const renderChartByVisualizationType = () => {
      switch (forecastChartMeteogramStore.visualizationType) {
        case VisualizationType.FORECAST:
          return (
            <Box>
              <ForecastChart
                chartHeight={chartHeight}
                style={{ maxWidth: windowSize.width }}
              />
            </Box>
          );
        case VisualizationType.PROBABILISTIC:
          return (
            <Box className="joyride-probabilistic" mr={"14px"}>
              <ProbabilisticChart
                chartHeight={chartHeight}
                style={{ maxWidth: windowSize.width }}
              />
            </Box>
          );
        case VisualizationType.SENSOR:
          if (forecastChartMeteogramStore.isSensorMarker) {
            return (
              <Box>
                <SensorChart
                  chartHeight={chartHeight}
                  style={{ maxWidth: windowSize.width }}
                />
              </Box>
            );
          } else {
            return (
              <Box>
                <ForecastChart
                  chartHeight={chartHeight}
                  style={{ maxWidth: windowSize.width }}
                />
              </Box>
            );
          }
        default:
          return (
            <Box>
              <ForecastChart
                chartHeight={chartHeight}
                style={{ maxWidth: windowSize.width }}
              />
            </Box>
          );
      }
    };

    const getMessageByVisualizationType = () => {
      const activityTab = i18n.t(
        `environmentalVariableEnum.${forecastChartPageStore.tabStateActivty}`
      );
      switch (forecastChartMeteogramStore.visualizationType) {
        case VisualizationType.FORECAST:
          return t("thereIsnotDataAvailable", {
            empty: "",
            location: activityTab,
            empty2: "",
          });
        case VisualizationType.PROBABILISTIC:
          return t("thereIsnotProbabilisticDataAvailable", {
            empty: "",
            location: activityTab,
            empty2: "",
          });
        case VisualizationType.SENSOR:
          if (forecastChartMeteogramStore.isSensorMarker) {
            return t("thereIsnotSensorDataAvailable", {
              empty: "",
              location: activityTab,
              empty2: "",
            });
          } else {
            return t("thereIsnotDataAvailable", {
              empty: "",
              location: activityTab,
              empty2: "",
            });
          }
        default:
          return t("thereIsnotDataAvailable", {
            empty: "",
            location: activityTab,
            empty2: "",
          });
      }
    };

    const getVisualizationTypeText = () => {
      switch (forecastChartMeteogramStore.visualizationType) {
        case VisualizationType.FORECAST:
          return t("forecastChart");
        case VisualizationType.PROBABILISTIC:
          return t("probabilisticChart");
        case VisualizationType.SENSOR:
          if (forecastChartMeteogramStore.isSensorMarker) {
            return t("sensorChart");
          } else {
            return t("forecastChart");
          }
        default:
          return t("forecastChart");
      }
    };

    const handleShowTutorial = (visualizationType: VisualizationType) => {
      const isSensor = visualizationType === VisualizationType.SENSOR;

      const isProbability =
        visualizationType === VisualizationType.PROBABILISTIC;

      popoverDisclosure.onClose();

      if (isSensor) {
        return setIfJoyrideIsDoneInStorage(
          JoyrideStorage.SENSOR_RIDE_ON_LOCAL_STORAGE,
          false
        );
      }

      if (isProbability) {
        return setIfJoyrideIsDoneInStorage(
          JoyrideStorage.PROBABILISTIC_ON_LOCAL_STORAGE,
          false
        );
      }

      setIfJoyrideIsDoneInStorage(
        JoyrideStorage.INITIAL_SENSOR_RIDE_ON_LOCAL_STORAGE,
        false
      );
      return setIfJoyrideIsDoneInStorage(
        JoyrideStorage.FORECAST_RIDE_ON_LOCAL_STORAGE,
        false
      );
    };

    const handleShowExtraTutorial = (type: JoyrideStorage) => {
      popoverDisclosure.onClose();

      const executeTutorialMapper: Record<JoyrideStorage, () => void> = {
        [JoyrideStorage.FORECAST_RIDE_ON_LOCAL_STORAGE]: () => {
          forecastChartMeteogramStore.handleChangeToForecastTab();
          forecastChartMeteogramStore.setVisualizationType(
            VisualizationType.FORECAST
          );
          setIfJoyrideIsDoneInStorage(
            JoyrideStorage.INITIAL_SENSOR_RIDE_ON_LOCAL_STORAGE,
            false
          );

          return setIfJoyrideIsDoneInStorage(
            JoyrideStorage.FORECAST_RIDE_ON_LOCAL_STORAGE,
            false
          );
        },
        [JoyrideStorage.GEOSPATIAL_ON_LOCAL_STORAGE]: () => {
          const hasSelectedStation = forecastChartPageStore.selectedStation > 0;
          const hasSelectedInsight =
            !!forecastChartMeteogramStore.selectedInsight;

          if (!hasSelectedStation && !hasSelectedInsight) {
            toast({
              title: t("selectStationAndInsight"),
              status: "info",
              position: "top",
              isClosable: true,
            });
          }
          if (hasSelectedStation && !hasSelectedInsight) {
            toast({
              title: t("selectInsight"),
              status: "info",
              position: "top",
              isClosable: true,
            });
          }

          if (!hasSelectedStation && hasSelectedInsight) {
            toast({
              title: t("selectStation"),
              status: "info",
              position: "top",
              isClosable: true,
            });
          }

          return setIfJoyrideIsDoneInStorage(
            JoyrideStorage.GEOSPATIAL_ON_LOCAL_STORAGE,
            false
          );
        },
        [JoyrideStorage.INITIAL_SENSOR_RIDE_ON_LOCAL_STORAGE]: () => {
          forecastChartMeteogramStore.setVisualizationType(
            VisualizationType.SENSOR
          );
          return setIfJoyrideIsDoneInStorage(
            JoyrideStorage.INITIAL_SENSOR_RIDE_ON_LOCAL_STORAGE,
            false
          );
        },
        [JoyrideStorage.PROBABILISTIC_ON_LOCAL_STORAGE]: () => {
          forecastChartProbabilisticStore.handleChangeToProbabilisticTab();
          return setIfJoyrideIsDoneInStorage(
            JoyrideStorage.PROBABILISTIC_ON_LOCAL_STORAGE,
            false
          );
        },
        [JoyrideStorage.SENSOR_RIDE_ON_LOCAL_STORAGE]: () => {
          analyticsChartStore.handleChangeToSensorTab();

          return setIfJoyrideIsDoneInStorage(
            JoyrideStorage.SENSOR_RIDE_ON_LOCAL_STORAGE,
            false
          );
        },
        [JoyrideStorage.METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE]: () => {
          setIfJoyrideIsDoneInStorage(
            JoyrideStorage.INITIAL_METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE,
            false
          );

          return setIfJoyrideIsDoneInStorage(
            JoyrideStorage.METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE,
            false
          );
        },
        [JoyrideStorage.INITIAL_METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE]: () => {
          return setIfJoyrideIsDoneInStorage(
            JoyrideStorage.INITIAL_METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE,
            false
          );
        },
      };

      executeTutorialMapper[type]();
    };

    const tutorialButtonLabel: Record<
      Exclude<
        JoyrideStorage,
        | JoyrideStorage.INITIAL_METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE
        | JoyrideStorage.INITIAL_SENSOR_RIDE_ON_LOCAL_STORAGE
      >,
      string
    > = {
      "forecast-ride": t("forecastTutorialLabel"),
      "geospatial-ride": t("geospatialTutorialLabel"),
      "sensor-ride": t("sensorTutorialLabel"),
      "probabilistic-ride": t("probabilisticTutorialLabel"),
      "metocean-report-ride": t("metOceanReportTutorialLabel"),
    };

    const tutorialButtons = Object.values(JoyrideStorage).filter(
      (
        joyride
      ): joyride is Exclude<
        JoyrideStorage,
        | JoyrideStorage.INITIAL_METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE
        | JoyrideStorage.INITIAL_SENSOR_RIDE_ON_LOCAL_STORAGE
      > =>
        joyride !==
          JoyrideStorage.INITIAL_METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE &&
        joyride !== JoyrideStorage.INITIAL_SENSOR_RIDE_ON_LOCAL_STORAGE
    );

    const showGreaterThan3HoursAlert =
      forecastChartMeteogramStore.isSensorMarker &&
      forecastChartMeteogramStore.visualizationType ===
        VisualizationType.SENSOR &&
      analyticsChartStore.isLastChartDateGreaterThan3Hours &&
      !forecastChartMeteogramStore.selectForecastDateAvailable;

    const disablePeriodButtonByVisualizationType =
      forecastChartMeteogramStore.visualizationType ===
        VisualizationType.PROBABILISTIC ||
      (forecastChartMeteogramStore.visualizationType ===
        VisualizationType.SENSOR &&
        forecastChartMeteogramStore.isSensorMarker);

    return (
      <>
        <ForecastUpdatePopover
          isOpen={popoverDisclosure.isOpen}
          onClose={popoverDisclosure.onClose}
        >
          <Flex direction="column" gap={3}>
            <Text color="blackAlpha.700">{t("watchTutorial")}</Text>
            {!showAllTutorials && (
              <Button
                color="white"
                bg="newBlue.500"
                border="1px solid #b0d2ec"
                _hover={{ backgroundColor: "rgba(49, 154, 221, 0.3)" }}
                onClick={() =>
                  handleShowTutorial(
                    forecastChartMeteogramStore.visualizationType
                  )
                }
              >
                {t("showTutorial")}
              </Button>
            )}

            {showAllTutorials &&
              tutorialButtons.map((joyride) => (
                <Button
                  color="white"
                  bg="newBlue.500"
                  border="1px solid #b0d2ec"
                  _hover={{ backgroundColor: "rgba(49, 154, 221, 0.3)" }}
                  onClick={() => handleShowExtraTutorial(joyride)}
                >
                  {tutorialButtonLabel[joyride]}
                </Button>
              ))}
            <Button
              fontSize="12px"
              fontWeight="light"
              bg="transparent"
              color="blackAlpha.900"
              textDecoration="underline"
              _hover={{ backgroundColor: "transparent" }}
              onClick={() => setShowAllTutorials((prev) => !prev)}
            >
              {showAllTutorials
                ? t("hideTutorialsListButton")
                : t("showTutorialsListButton")}
            </Button>
          </Flex>
        </ForecastUpdatePopover>
        <Box
          position={
            forecastChartMeteogramStore.openMeteogram ? "absolute" : undefined
          }
          w={"100%"}
          zIndex={99999999}
          bottom={0}
          right={0}
        >
          <Box>
            {shareStore.stations?.length > 0 && (
              <>
                <ShareFormModal
                  key={`id-${shareStore.stations[0]?.id}`}
                  users={usersList}
                  stations={shareStore.stations}
                  isOpen={isOpen}
                  onClose={handleCloseNewsModal}
                  storeState={shareStore.state}
                  shareStore={shareStore}
                />

                <MetOceanReportModal
                  key={`${forecastChartPageStore.selectedStation}-${forecastChartPageStore.regionId}`}
                  stations={shareStore.stations}
                  isOpen={isTabularConfigOpen}
                  onClose={onClose}
                  selectedStationId={forecastChartPageStore.selectedStation}
                  selectedRegionId={forecastChartPageStore.regionId}
                />
              </>
            )}

            <Flex
              wrap={isSmallScreen ? undefined : "wrap"}
              justifyContent="space-between"
              alignItems="flex-end"
              gap={2}
              fontSize={isSmallScreen ? "12px" : undefined}
            >
              <Flex
                alignItems="center"
                gap={2}
                pl={SCREEN_HORIZONTAL_SPACING}
                hidden={!forecastChartMeteogramStore.openMeteogram}
                position="absolute"
                left={0}
              >
                <ForecastChartPastForecast
                  disable={
                    !forecastChartPageStore.stationWithHistoricalData(
                      forecastChartPageStore.selectedStation
                    ) ||
                    !(forecastChartPageStore.selectedStation > 0) ||
                    forecastChartMeteogramStore.isChartDataEmpty ||
                    forecastChartMeteogramStore.visualizationType ===
                      VisualizationType.PROBABILISTIC
                  }
                />
                <Box zIndex="999999" alignItems="center">
                  <ButtonGroup
                    isAttached
                    color={"white"}
                    size="xs"
                    zIndex="999999"
                  >
                    <Button
                      disabled={
                        forecastChartMeteogramStore.isChartDataEmpty ||
                        disablePeriodButtonByVisualizationType
                      }
                      size="xs"
                      backdropFilter={"blur(2px)"}
                      bg={
                        forecastChartMeteogramStore.periodSelected === 24 &&
                        !forecastChartProbabilisticStore.showProbabilisticChart
                          ? "newBlue.500"
                          : "rgba(0, 0, 0, 0.37)"
                      }
                      borderRight="1px solid"
                      boxSizing="border-box"
                      _hover={{
                        backgroundColor:
                          forecastChartMeteogramStore.periodSelected !== 24 &&
                          "rgba(0, 0, 0, 0.47)",
                      }}
                      onClick={() => handleChangeChartPeriod(24)}
                      borderRadius={4}
                    >
                      24h
                    </Button>
                    <Button
                      disabled={
                        !forecastChartMeteogramStore.show48hButton ||
                        forecastChartMeteogramStore.isChartDataEmpty ||
                        disablePeriodButtonByVisualizationType
                      }
                      size="xs"
                      backdropFilter={"blur(2px)"}
                      bg={
                        forecastChartMeteogramStore.periodSelected === 48 &&
                        !forecastChartProbabilisticStore.showProbabilisticChart
                          ? "newBlue.500"
                          : "rgba(0, 0, 0, 0.37)"
                      }
                      borderRight="1px solid"
                      boxSizing="border-box"
                      _hover={{
                        backgroundColor:
                          forecastChartMeteogramStore.periodSelected !== 48 &&
                          "rgba(0, 0, 0, 0.47)",
                      }}
                      onClick={() => handleChangeChartPeriod(48)}
                      borderRadius={4}
                    >
                      48h
                    </Button>
                    <Button
                      disabled={
                        !forecastChartMeteogramStore.show72hButton ||
                        forecastChartMeteogramStore.isChartDataEmpty ||
                        disablePeriodButtonByVisualizationType
                      }
                      size="xs"
                      backdropFilter={"blur(2px)"}
                      bg={
                        forecastChartMeteogramStore.periodSelected === 72 &&
                        !forecastChartProbabilisticStore.showProbabilisticChart
                          ? "newBlue.500"
                          : "rgba(0, 0, 0, 0.37)"
                      }
                      borderRight="1px solid"
                      boxSizing="border-box"
                      _hover={{
                        backgroundColor:
                          forecastChartMeteogramStore.periodSelected !== 72 &&
                          "rgba(0, 0, 0, 0.47)",
                      }}
                      onClick={() => handleChangeChartPeriod(72)}
                      borderRadius={4}
                    >
                      72h
                    </Button>
                    <Button
                      disabled={
                        forecastChartMeteogramStore.isChartDataEmpty ||
                        (forecastChartMeteogramStore.visualizationType ===
                          VisualizationType.SENSOR &&
                          forecastChartMeteogramStore.isSensorMarker)
                      }
                      size="xs"
                      bg={
                        forecastChartMeteogramStore.periodSelected ===
                          undefined ||
                        forecastChartProbabilisticStore.showProbabilisticChart
                          ? "newBlue.500"
                          : "rgba(0, 0, 0, 0.37)"
                      }
                      _hover={{
                        backgroundColor:
                          forecastChartMeteogramStore.periodSelected !==
                            undefined && "rgba(0, 0, 0, 0.47)",
                      }}
                      backdropFilter="blur(2px)"
                      onClick={() => handleChangeChartPeriod()}
                      borderRadius={4}
                    >
                      {t("all")}
                    </Button>
                  </ButtonGroup>
                </Box>
                <Box>
                  <Tooltip
                    label={t("dataInterval")}
                    fontSize="sm"
                    placement="top"
                    hasArrow
                  >
                    <Button
                      layerStyle="darkOcean"
                      _hover={{ layerStyle: "darkOcean" }}
                      size="xs"
                      zIndex="999999"
                      borderRadius={4}
                      cursor="auto"
                    >
                      {renderRealInterval() &&
                      !forecastChartMeteogramStore.isChartDataEmpty
                        ? renderRealInterval()
                        : "----"}
                    </Button>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip
                    label={t("refreshToseeNewData")}
                    fontSize="sm"
                    placement="top"
                    hasArrow
                  >
                    <Button
                      disabled={forecastChartMeteogramStore.isChartDataEmpty}
                      layerStyle="darkOcean"
                      _hover={{ layerStyle: "darkOcean" }}
                      _disabled={{ layerStyle: "darkOcean" }}
                      size="xs"
                      onClick={() => {
                        handleRefreshChart();
                      }}
                      zIndex="999999"
                      borderRadius={4}
                    >
                      <CachedIcon style={{ width: "19px", height: "19px" }} />
                    </Button>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip
                    label={t("share")}
                    fontSize="sm"
                    placement="top"
                    hasArrow
                  >
                    <Button
                      layerStyle="darkOcean"
                      _hover={{ layerStyle: "darkOcean" }}
                      size="xs"
                      onClick={handleOpenNewsModal}
                      disabled={
                        !!forecastChartMeteogramStore.selectForecastDateAvailable ||
                        !shareStore.stations.length ||
                        forecastChartMeteogramStore.visualizationType ===
                          VisualizationType.SENSOR
                      }
                      zIndex="999999"
                      borderRadius={4}
                    >
                      <ShareIcon style={{ width: "17px", height: "17px" }} />
                    </Button>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip
                    label={t("metOceanForecastReport")}
                    fontSize="sm"
                    placement="top"
                    hasArrow
                  >
                    <Button
                      className="joyride-metocean-button"
                      layerStyle="darkOcean"
                      _hover={{ layerStyle: "darkOcean" }}
                      size="xs"
                      onClick={onOpen}
                      disabled={
                        !!forecastChartMeteogramStore.selectForecastDateAvailable ||
                        !shareStore.stations.length ||
                        forecastChartMeteogramStore.visualizationType ===
                          VisualizationType.SENSOR
                      }
                      zIndex="999999"
                      borderRadius={4}
                    >
                      <PictureAsPdfIcon
                        style={{ width: "19px", height: "19px" }}
                      />
                    </Button>
                  </Tooltip>
                </Box>
              </Flex>

              <Box
                display={"grid"}
                zIndex={999999}
                gap={"7px"}
                position="fixed"
                right={0}
              >
                {forecastChartPageStore.tabStateActivty ===
                  EnvironmentalType.PRECIPITATION &&
                  forecastChartPageStore.radarData.length > 0 && (
                    <RadarLegend />
                  )}

                {forecastChartPageStore.emptyRadarData && (
                  <Box>
                    <Alert
                      py="8px"
                      status="warning"
                      zIndex={9999}
                      color="ButtonText"
                      borderRadius="4px"
                      fontSize="11px"
                      marginRight={5}
                      width={300}
                    >
                      <AlertIcon />
                      {t("radarDataUnavailable")}
                    </Alert>
                  </Box>
                )}

                {forecastChartPageStore.insightMode &&
                  forecastChartMeteogramStore.selectedInsight && (
                    <Box className="joyride-geoespatial-legend">
                      <ForecastChartInsightLegend
                        legendItems={getLegendItems(
                          forecastChartMeteogramStore.selectedInsight
                            .restrictionType
                        )}
                        outerTooltip={
                          forecastChartInsightStore.timelineInterval
                        }
                      />
                    </Box>
                  )}

                <Flex
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems="center"
                  mb={
                    forecastChartMeteogramStore.openMeteogram ||
                    (forecastChartPageStore.insightMode &&
                      forecastChartMeteogramStore.selectedInsight)
                      ? "0px"
                      : "7px"
                  }
                >
                  <VisualizationTypeMenu />

                  <Flex
                    gridColumn={"initial"}
                    mr={SCREEN_HORIZONTAL_SPACING}
                    zIndex="999999"
                    cursor={"pointer"}
                    onClick={popoverDisclosure.onOpen}
                    mb={
                      forecastChartMeteogramStore.openMeteogram ? "0px" : "7px"
                    }
                  >
                    <Tooltip
                      label={t("clickHereToOpenTheGuide")}
                      fontSize="sm"
                      placement="top-start"
                      hasArrow
                    >
                      <IconButton
                        w="auto"
                        h="auto"
                        bg="transparent"
                        _hover={{
                          bg: "transparent",
                        }}
                        _active={{
                          bg: "transparent",
                        }}
                        aria-label="Info icon"
                        onClick={() => {
                          popoverDisclosure.onOpen();
                        }}
                      >
                        <img src={Info} alt="Info icon" />
                      </IconButton>
                    </Tooltip>
                  </Flex>
                </Flex>

                {showGreaterThan3HoursAlert && (
                  <Alert
                    py="8px"
                    status="warning"
                    zIndex={9999}
                    width={300}
                    color="ButtonText"
                    borderRadius="4px"
                    fontSize="11px"
                    flexWrap="nowrap"
                  >
                    <AlertIcon />
                    {t("delayedSensor")}{" "}
                    {analyticsChartStore.lastChartDate &&
                      getTimeDifference(
                        new Date(analyticsChartStore.lastChartDate)
                      )
                        .toLowerCase()
                        .replace("ago", "late")}
                  </Alert>
                )}
              </Box>
            </Flex>

            <Box bg="#ffffff" width="100%">
              <I4castAlert
                style={{ position: "absolute", zIndex: 9, width: "100%" }}
                visible={forecastChartMeteogramStore.state === StoreState.ERROR}
                message={forecastChartMeteogramStore.messageError}
                type="error"
              />

              <Box
                mt={"7px"}
                pt={"8px"}
                h={
                  forecastChartMeteogramStore.openMeteogram
                    ? containerHeight
                    : "0px"
                }
                transition="transform .2s ease-in-out"
                opacity={forecastChartMeteogramStore.openMeteogram ? 1 : 0}
                display={
                  forecastChartMeteogramStore.openMeteogram ? "grid" : "none"
                }
                pointerEvents={
                  forecastChartMeteogramStore.openMeteogram ? "auto" : "none"
                }
                bg="#ffffff"
                p={"4px"}
              >
                {!isLoading && showVisualizationTypeText && (
                  <Flex
                    zIndex={999999999999}
                    position={"absolute"}
                    right={"50%"}
                    mt="20px"
                  >
                    <TextChakra color="gray.500">
                      {getVisualizationTypeText()}
                    </TextChakra>
                  </Flex>
                )}

                <Flex
                  zIndex={999999999999}
                  position={"absolute"}
                  right={0}
                  mr={"5px"}
                >
                  <Tooltip
                    label={t("closeChart")}
                    placement={"bottom-start"}
                    hasArrow
                  >
                    <IconButton
                      colorScheme="red"
                      icon={
                        <CloseIcon
                          style={{ width: "0.65em", height: "0.85em" }}
                        />
                      }
                      p={"5px"}
                      size="xs"
                      onClick={() => handleCloseMeteogram()}
                      aria-label={"close button"}
                    />
                  </Tooltip>
                </Flex>

                <Center>
                  {!isLoading && noDataAvailable && (
                    <Box>
                      <Center>
                        <Box width={"115px"}>
                          <Lottie animationData={noData} />
                        </Box>
                      </Center>
                      <Center>
                        <TextChakra color={"blue.100"} fontSize="md">
                          {getMessageByVisualizationType()}
                        </TextChakra>
                      </Center>
                    </Box>
                  )}

                  {!isLoading && showErrorOnChart && (
                    <Box>
                      <Center>
                        <Box width={"115px"}>
                          <Lottie animationData={error} />
                        </Box>
                      </Center>
                      <Center>
                        <TextChakra color={"blue.100"} fontSize="md">
                          {t("somethingWrong")}
                        </TextChakra>
                      </Center>
                    </Box>
                  )}
                </Center>

                {showForecast &&
                  !showVisibility &&
                  forecastChartPageStore.tabStateActivty ===
                    EnvironmentalType.VISIBILITY && (
                    <EmptyChartWithContact
                      ChartComponent={<VisibilityChartMock />}
                      message={t("thisRegionDoesNotHaveVisibility")}
                      contactMessage={t("contactOurTeamToEnsure")}
                      buttonMessage={t("getInTouch")}
                      onContactClick={handleRedirectToContact}
                      width="500px"
                      padding="12px"
                    />
                  )}

                {showForecast &&
                  !hasRainData &&
                  forecastChartPageStore.tabStateActivty ===
                    EnvironmentalType.PRECIPITATION && (
                    <EmptyChartWithContact
                      ChartComponent={<RainChartMock />}
                      message={t("thisRegionDoesNotHavePrecip")}
                      contactMessage={t("contactOurTeamToEnsure")}
                      buttonMessage={t("getInTouch")}
                      onContactClick={handleRedirectToContact}
                      width="500px"
                      padding="12px"
                    />
                  )}

                {showNoProbabilisticDataMessage && (
                  <EmptyChartWithContact
                    ChartComponent={<ProbabilisticChartMock />}
                    message={t("itAppearsThatYourRegion")}
                    contactMessage={t("contactOurTeamToEnsure")}
                    onContactClick={handleRedirectToContact}
                    buttonMessage={t("getInTouch")}
                    width="auto"
                    padding="8px"
                    fontSize="md"
                  />
                )}

                {isLoading && (
                  <Center position="absolute" inset={0}>
                    <Spinner
                      thickness="4px"
                      color="newBlue.500"
                      size="xl"
                      zIndex={99999999999}
                    />
                  </Center>
                )}
                <Box>
                  {showChart && !isLoading ? (
                    <>{renderChartByVisualizationType()}</>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

export default observer(ForecastChartPanelMeteogram);
